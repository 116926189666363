import React from 'react';
import cs from 'classnames';
import { Flex, Text } from 'shared/components/display';
import styles from './styles';
import { TEXT_VARIANTS } from 'shared/styles/text';
import FileInformationModal from 'features/media/components/molecules/FileInformationModal';
import { BrkfstFile } from 'shared/typings/file';

interface Props {
  captionData: BrkfstFile;
}
const FileLightboxFooter: React.FC<Props> = ({ captionData }) => {
  return (
    <Flex css={styles} className={cs('file-lightbox-footer')}>
      <Text variant={TEXT_VARIANTS.BODY} className="file-lightbox-footer__caption">
        {captionData.name}
      </Text>
      <FileInformationModal file={captionData} />
    </Flex>
  );
};

export default FileLightboxFooter;
