import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import OrganizationOnboarding from 'features/organizations/pages/OrganizationOnboarding';
import RedirectMarketer from 'features/organizations/RedirectMarketer';
import { NoAccessMsg } from 'features/ui/pages/ErrorPages';
import TestErrorBoundaryPage from 'features/ui/pages/TestErrorBoundaryPage/TestErrorBoundaryPage';

import BrandRoutesWrapper from 'shared/router/brandRoutes/BrandRoutes/BrandRoutesWrapper';
import BrandUserRoutes from 'shared/router/brandRoutes/BrandUserRoutes';
import OrgRoutes from 'shared/router/brandRoutes/OrgRoutes';
import RedirectToAccountHealth from 'shared/router/brandRoutes/RedirectToAccountHealth';
import RedirectToTrends from 'shared/router/brandRoutes/RedirectToTrends';

// Org Routes > Account Routes > Platform Routes
const BrandRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<BrandRoutesWrapper />}>
        <Route index element={<RedirectMarketer />} />
        <Route path="/error" element={<TestErrorBoundaryPage />} />
        <Route path="/org/:organizationId/*" element={<OrgRoutes />} />
        <Route path="/user/*" element={<BrandUserRoutes />} />
        <Route path="/no-access" element={<NoAccessMsg />} />
        <Route path="/org-onboarding" element={<OrganizationOnboarding />} />
        <Route path="/account-health" element={<RedirectToAccountHealth />} />
        <Route path="/trends" element={<RedirectToTrends />} />
        <Route path="*" element={<Navigate to="/not-found" />} />
      </Route>
    </Routes>
  );
};

export default BrandRoutes;
