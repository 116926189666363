import React, { Fragment, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Image } from 'rebass/styled-components';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findKey, startCase } from 'lodash';
import styled from 'styled-components';

import { InputValue, LabelValue } from 'features/accountPlatforms/facebook';
import { Box, Text } from 'shared/components/display';

import {
  AD_NAME,
  AD_NAME_PREVIEW,
  ADD_END_CARD,
  ADS,
  CALL_TO_ACTION,
  DISPLAY_URL,
  SEE_MORE_URL,
  URL_TAGS,
} from 'shared/config/ads';
import { FACEBOOK_COPY } from 'shared/config/copy';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';
import theme from 'shared/styles/themes';
import { parseDynamicString } from 'shared/utilities/stringUtility';

const AssetsGrid = ({ adCreative }) => {
  const { asset } = adCreative;
  return (
    <AssetsGridContainer my={8}>
      <Text variant={TEXT_VARIANTS.SUBHEADING}>{FACEBOOK_COPY.HEADING_ASSET}</Text>
      <Asset>
        <Image src={asset.thumbnailUrl} alt={asset.name} />
        <InputValue variant={TEXT_VARIANTS.BODY} className="endWithEllipsis">
          {asset.name}
        </InputValue>
      </Asset>
      {ADS.FACEBOOK.CAROUSEL_AD_INPUTS.ASSET.map(({ fieldName }) => (
        <Fragment key={fieldName}>
          <Text variant={TEXT_VARIANTS.SUBHEADING}>{startCase(fieldName)}</Text>
          <InputValue variant={TEXT_VARIANTS.BODY}>{adCreative[fieldName]}</InputValue>
        </Fragment>
      ))}
    </AssetsGridContainer>
  );
};

const FbAdCheckoutCarousel = () => {
  const location = useLocation<{ moduleNum: number; formData: any }>();
  const { formData, moduleNum } = location.state;

  const adNamePreview = useMemo(() => {
    const creatorName =
      `${formData.carouselAssets[0].asset.creatorLastName}${formData.carouselAssets[0].asset.creatorFirstName}`.replace(
        /\s/g,
        '',
      );
    return parseDynamicString(formData.adName, {
      ...formData,
      creatorName,
      moduleNumber: moduleNum,
    });
  }, [formData, moduleNum]);

  return (
    <Fragment>
      {formData.carouselAssets.map((creative) => (
        <AssetsGrid adCreative={creative} key={creative.asset.id} />
      ))}
      {ADS.FACEBOOK.CAROUSEL_AD_INPUTS.DYNAMIC.map(({ fieldName }) => (
        <LabelValue key={fieldName} label={startCase(fieldName)} value={formData[fieldName]} />
      ))}
      {ADS.FACEBOOK.CAROUSEL_AD_INPUTS.BOOLEAN.map((fieldname) => (
        <BooleanLabelValue variant={TEXT_VARIANTS.SUBHEADING} key={fieldname}>
          <FontAwesomeIcon
            color={formData[fieldname] ? COLORS.GREEN500 : COLORS.RED500}
            icon={formData[fieldname] ? faCheckCircle : faTimesCircle}
          />
          {fieldname === ADD_END_CARD ? FACEBOOK_COPY.LABEL_ADD_END_CARD : FACEBOOK_COPY.LABEL_OPTIMIZE}
        </BooleanLabelValue>
      ))}
      <LabelValue
        label={startCase(CALL_TO_ACTION)}
        value={findKey(ADS.FACEBOOK.CTA, (cta) => cta === formData[CALL_TO_ACTION])}
      />
      <LabelValue label={startCase(DISPLAY_URL)} value={formData[DISPLAY_URL]} />
      <LabelValue label={startCase(SEE_MORE_URL)} value={formData[SEE_MORE_URL]} />
      <LabelValue label={startCase(AD_NAME)} value={formData[AD_NAME]} />
      <LabelValue label={AD_NAME_PREVIEW} value={adNamePreview} />
      <LabelValue label={startCase(URL_TAGS)} value={formData[URL_TAGS]} />
    </Fragment>
  );
};

const AssetsGridContainer = styled(Box)`
  display: grid;
  grid-template-columns: 2fr 2fr;
  column-gap: 1rem;
  row-gap: 0.5rem;

  img {
    float: left;
    margin-right: 0.5rem;
    width: 3rem;
  }
`;

const Asset = styled.div`
  grid-column-start: span 2;
`;

const BooleanLabelValue = styled(Text)`
  margin-bottom: ${theme.space[3]};
  svg {
    margin-right: 0.5rem;
  }
`;

export default FbAdCheckoutCarousel;
