import React, { useState } from 'react';
import { Button } from 'shared/components/display';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import Modal from 'shared/components/molecules/Modal';
import TrendForm from 'features/trends/components/molecules/TrendForm';
import { intl } from 'shared/lib/intl';

interface Props {
  onSubmit: (data) => void;
  onDelete?: () => void;
  trend?: any;
  onClick?: () => void;
}
const TrendFormModal: React.FC<Props> = ({ onSubmit, trend, onClick, onDelete }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const isNew = !Boolean(trend);

  const toggleModal = () => {
    setModalOpen((prev) => !prev);
  };

  return (
    <>
      <Button
        variant={BUTTON_VARIANTS.OPTION}
        onClick={() => {
          // For when used in Dropdown, onClick is passed as a prop
          // to close the dropdown
          if (onClick) {
            onClick();
          }
          toggleModal();
        }}
      >
        {intl.formatMessage(
          {
            id: 'TREND_FORM_MODAL_BUTTON_TEXT',
          },
          {
            isNew,
          },
        )}
      </Button>
      <Modal
        title={intl.formatMessage(
          {
            id: 'TREND_FORM_MODAL_HEADER',
          },
          {
            isNew,
          },
        )}
        isOpen={modalOpen}
        onRequestClose={toggleModal}
        component={
          <TrendForm
            values={trend}
            onDelete={onDelete}
            onCancel={toggleModal}
            isNew={isNew}
            onSubmit={(data) => {
              onSubmit(data);
              toggleModal();
            }}
          />
        }
      />
    </>
  );
};

export default TrendFormModal;
