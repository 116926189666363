import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.creator-invite-form': {},
  '.creator-invite-form': {
    '&__form': {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.space[4],
    },
    '&__description': {
      color: COLORS.NEUTRAL600,
      marginBottom: 0,
    },
    '&__info-message': {
      margin: `${theme.space[4]} 0`,
    },
    '&__button-wrapper': {
      justifyContent: 'flex-end',
      marginBottom: theme.space[4],
      gap: theme.space[3],
    },
    '&__hr': {
      margin: '0 -1rem',
      width: 'calc(100% + 2rem)',
    },
    '&__error': {
      color: COLORS.RED500,
      margin: 0,
    },
  },
});
