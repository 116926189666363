export const TRENDS_LIBRARY_COPY = {
  BUTTON_DELETE_TREND: 'Delete Trend',
  BUTTON_CREATE_TREND: 'Create Trend',
  BUTTON_EDIT_TREND: 'Update',
  TREND_LIBRARY_HEADER: 'Discover What’s <pink>Trending</pink>',

  TREND_FORM_SUB_HEADER: 'Name your trend and upload a reference.',
  TREND_FORM_MODAL_HEADER: '{isNew, select, true {New} other {Edit}} Trend',
  TREND_FORM_MODAL_BUTTON_TEXT: '{isNew, select, true {New} other {Edit}} Trend',

  TOAST_CREATE_TREND_SUCCESS: 'Trend created successfully',
  TOAST_UPDATE_TREND_SUCCESS: 'Trend updated successfully',
  TOAST_DELETE_TREND_SUCCESS: 'Trend deleted successfully',
  TOAST_PIN_TREND_MAX: 'You cannot pin more than {max} trends',

  PROMPT_DELETE_TREND: 'Are you sure you want to delete this trend?',

  VALIDATION_TREND_DURATION: 'Above max duration of {max}',
  VALIDATION_TREND_FILE_REQUIRED: 'Please upload a valid file',

  COPY_TEXT: 'Copy Link',
  COPIED_TEXT: 'Copied!',
};
