import React from 'react';
import { Button } from 'rebass/styled-components';
import { FormikErrors, FormikTouched } from 'formik';

import { Box, Flex, Text } from 'shared/components/display';
import Richtext, { RICHTEXT_DEFAULT_VALUE } from 'shared/components/Richtext';

import { BRIEF_COPY } from 'shared/config/copy';
import { intl } from 'shared/lib/intl';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { TEXT_VARIANTS } from 'shared/styles/text';

import { FormBrief, ModuleSceneValues } from '../../types';
import { MIN_SCENES, SCENE_FIELD_NAMES } from '../../utils';

import styles from './styles';

interface ModuleSceneTitleProps {
  idx: number;
  handleRemove: () => void;
  values: ModuleSceneValues[];
  onEditScene: (idx: number) => void;
  moduleIndex: number;
  formikValues: FormBrief;
  errors: FormikErrors<FormBrief>;
  touched: FormikTouched<FormBrief>;
}

const ModuleSceneTitle = ({
  idx,
  handleRemove,
  values,
  onEditScene,
  moduleIndex,
  formikValues,
  errors,
  touched,
}: ModuleSceneTitleProps) => {
  const sceneTitleValue = formikValues.modules[moduleIndex].scenes[idx][`${SCENE_FIELD_NAMES.TITLE}`];
  const hasDescription =
    JSON.stringify(formikValues.modules[moduleIndex].scenes[idx][`${SCENE_FIELD_NAMES.DESCRIPTION}`]) !=
    JSON.stringify(RICHTEXT_DEFAULT_VALUE);
  // @ts-ignore
  const sceneErrors = errors?.modules?.[moduleIndex]?.scenes?.[idx] && !!touched?.modules?.[moduleIndex]?.scenes?.[idx];
  return (
    <Box css={styles}>
      <Flex className="module-scene-title__scene-wrapper">
        <Flex className="module-scene-title__scene-title-wrapper">
          <Text tabIndex={0} id={`modules[${moduleIndex}].scenes[${idx}]`} className="module-scene-title__label "  variant={TEXT_VARIANTS.SUBHEADING}>
            {intl.formatMessage({ id: 'SUBHEADING_SCENE' }, { num: idx + 1 })}
            {`${sceneTitleValue.length > 0 ? `- ${sceneTitleValue}` : ''} `}
          </Text>
          <Button type="button" onClick={() => onEditScene(idx)} variant={BUTTON_VARIANTS.TEXT_ONLY}>
            {BRIEF_COPY.BUTTON_EDIT_SCENE}
          </Button>
          <Button
            type="button"
            variant={BUTTON_VARIANTS.TEXT_ONLY}
            className="module-scene-title__delete-btn"
            disabled={values.length <= MIN_SCENES}
            onClick={handleRemove}
          >
            {BRIEF_COPY.BUTTON_REMOVE_SCENE}
          </Button>
          <Box className="module-scene-title__scene-error">{sceneErrors && BRIEF_COPY.VALIDATION_SCENE_ERROR}</Box>
        </Flex>
        {hasDescription ? (
          <Richtext
            value={formikValues.modules[moduleIndex].scenes[idx][`${SCENE_FIELD_NAMES.DESCRIPTION}`]}
            readOnly
            key={`${moduleIndex}-${idx}`}
          />
        ) : (
          <></>
        )}
      </Flex>
    </Box>
  );
};

export default ModuleSceneTitle;
