import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export default css({
  '&.module-scenes-form': {
    marginTop: theme.space[4],
  },
  '.module-scenes-form': {
    '&__header': {
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '&__label': {
      marginBottom: 0,
    },
    '&__input': {
      maxWidth: '800px',
    },
    '&__add-btn': {
      marginTop: '20px',
      [mediaquery.md]: {
        marginRight: 'auto',
      },
    },
    '&__delete-btn': {
      color: COLORS.NEUTRAL600,
    },
    '&__scene-title-wrapper': {
      alignItems: 'center',
    },
    '&__scene-wrapper': {
      maxWidth: '800px',
    },
    '&__scene-error': {
      position: 'relative',
      color: COLORS.RED500,
      fontSize: theme.fontSizes[1],
      top: '2px',
    },
  },
});
