import React from 'react';
import EllipticalMenu from 'shared/components/EllipticalMenu/EllipticalMenu';
import TrendFormModal from 'features/trends/components/organisms/TrendFormModal';
import { Flex } from 'shared/components/display';
import PromptButton from 'shared/components/molecules/PromptButton';
import { TRENDS_LIBRARY_COPY } from 'features/trends/pages/TrendsLibrary/copy';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { useTrends } from 'features/trends/hooks/useTrends';
import { TrendFormValues } from 'features/trends/components/molecules/TrendForm/config';

import styles from './styles';

interface Props {
  showNewButton?: boolean;
  showEditButton?: boolean;
  showDeleteButton?: boolean;
  portalTarget?: string;
  trendId?: number;
  onDelete?: () => void;
}
const TrendEllipsisMenu: React.FC<Props> = ({
  showNewButton = false,
  showEditButton = false,
  showDeleteButton = false,
  portalTarget,
  trendId,
  onDelete: onDeleteProp,
}) => {
  const { trend, deleteTrend, updateTrend, createTrend } = useTrends(trendId);

  const onDelete = () => {
    if (trendId) deleteTrend(trendId);
    if (onDeleteProp) onDeleteProp();
  };

  const onEditTrend = (data: TrendFormValues) => {
    if (trendId)
      updateTrend(trendId, {
        name: data.name,
        fileId: data.file.id,
        deleteFile: data.file.id !== trend?.file.id,
      });
  };

  const onCreateTrend = (data: TrendFormValues) => {
    createTrend({
      name: data.name,
      fileId: data.file.id,
    });
  };

  return (
    <Flex css={styles} className="trend-ellipsis-menu">
      <EllipticalMenu portalTarget={portalTarget}>
        {showNewButton && <TrendFormModal onSubmit={onCreateTrend} />}
        {showEditButton && <TrendFormModal trend={trend} onSubmit={onEditTrend} onDelete={onDelete} />}
        {showDeleteButton && (
          <PromptButton
            text={TRENDS_LIBRARY_COPY.PROMPT_DELETE_TREND}
            onConfirm={onDelete}
            variant={BUTTON_VARIANTS.OPTION}
          >
            {TRENDS_LIBRARY_COPY.BUTTON_DELETE_TREND}
          </PromptButton>
        )}
      </EllipticalMenu>
    </Flex>
  );
};

export default TrendEllipsisMenu;
