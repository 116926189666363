import React, { useMemo } from 'react';

import CreatorFileOverlayIcons from 'features/media/components/molecules/CreatorFileOverlayIcons';
import MarketerFileOverlayIcons from 'features/media/components/molecules/MarketerFileOverlayIcons';
import { GalleryItem } from 'features/media/interfaces/gallery';
import ModuleAssetsEllipsis from 'features/submissions/components/molecules/ModuleAssetsEllipsis';
import { Text } from 'shared/components/display';
import MediaGalleryAndListDisplay from 'shared/components/organisms/MediaGalleryAndListDisplay';

import { SUBMISSION_COPY } from 'shared/config/copy/submissionCopy';
import { PERMISSIONS_MAP } from 'shared/config/permissions';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { usePermissions } from 'shared/hooks/usePermissions';
import { BrkfstFile } from 'shared/typings/file';
import { ModuleSubmissionStatus } from 'shared/typings/submissions';

interface Props {
  assets: BrkfstFile[];
  status: ModuleSubmissionStatus;
  organizationId: number;
  moduleNum: number;
  setSelectedFiles?: React.Dispatch<React.SetStateAction<BrkfstFile[]>>;
  selectedFiles?: BrkfstFile[];
}

const ModuleAssets: React.FC<Props> = ({
  assets,
  moduleNum,
  status,
  organizationId,
  selectedFiles,
  setSelectedFiles,
}) => {
  const { isCreator, isSuperadmin = false } = useCurrentUser();
  const { isAuthorized } = usePermissions();
  const selectedFileIds = useMemo(() => selectedFiles?.map((file) => file.id), [selectedFiles]);

  const updateSelectedItems = (fileIds: number[]) => {
    // Create Set for quick access
    const fileIdsSet = new Set(fileIds);
    // Grab file objects that were selected from Ids
    const moduleAssetsChosen = assets.filter((file) => fileIdsSet.has(file.id));
    setSelectedFiles?.((existingFiles) => {
      // Get all module files that have already been chosen
      const moduleFiles = existingFiles.filter(({ moduleSubmission }) => moduleSubmission.moduleNum === moduleNum);
      const moduleFileIds = moduleFiles.map((file) => file.id);
      // Get all module files that have been removed
      const fileIdsRemoved = moduleFileIds.filter((id) => !fileIdsSet.has(id));
      // Remove files from callback argument array that have been removed
      const remainingFiles = existingFiles.filter((file) => !fileIdsRemoved.includes(file.id));

      // Create Set for quick access
      const existingFileIds = new Set(existingFiles.map((file) => file.id));
      // Find all files that are new
      const newFiles = moduleAssetsChosen.filter((file) => !existingFileIds.has(file.id));
      // Return all files that are still selected
      return [...remainingFiles, ...newFiles];
    });
  };

  const hideModuleInSubmittedState = useMemo(() => {
    const hasPermissionToViewSubmittedState =
      isCreator || isAuthorized(organizationId, PERMISSIONS_MAP.VIEW_SUBMITTED_SUBMISSION_MODULES.API);
    return !hasPermissionToViewSubmittedState && status == ModuleSubmissionStatus.SUBMITTED;
  }, [organizationId, isCreator, status]);

  const formatGalleryItems = (file: BrkfstFile): GalleryItem => {
    const { submitted, ...marketerFile } = file;

    return {
      options: <ModuleAssetsEllipsis file={isCreator ? file : marketerFile} />,
      itemObject: isCreator ? file : marketerFile,
      captionData: isCreator ? file : marketerFile,
      OverlayLeft: isCreator ? (
        <CreatorFileOverlayIcons file={file} showTags />
      ) : (
        <MarketerFileOverlayIcons file={marketerFile} showTags />
      ),
    };
  };

  return hideModuleInSubmittedState ? (
    <Text>{SUBMISSION_COPY.PLACEHOLDER_SUBMITTED_MODULES}</Text>
  ) : (
    <MediaGalleryAndListDisplay
      data={assets}
      isSelectable={isSuperadmin}
      formatGalleryItems={formatGalleryItems}
      updateSelectedItems={updateSelectedItems}
      selectedFileIds={selectedFileIds}
    />
  );
};

export default ModuleAssets;
