import cs from 'classnames';
import React, { useState } from 'react';
import { Box, Button, Flex, Text } from 'shared/components/display';
import Tooltip from 'shared/components/atoms/Tooltip/NewTooltip';
import { CreatorInviteForm, InviteCreatorValues } from 'features/users/components/organisms/CreatorInviteForm';
import Modal from 'shared/components/molecules/Modal';
import { CREATOR_COPY } from 'shared/config/copy';
import { SIZING } from 'shared/config/formatting';

import { BUTTON_VARIANTS } from 'shared/styles/button';

import { useBriefs } from 'features/briefs/hooks/useBriefs';

import styles from './styles';

interface Props {
  creatorEmail: string;
  creatorId: number;
  disabled?: boolean;
}

const CreatorInviteFormModal: React.FC<Props> = ({ creatorEmail, disabled, creatorId }) => {
  const [state, setState] = useState<{
    modalOpen: boolean;
    submitted: boolean;
  }>({
    modalOpen: false,
    submitted: false,
  });

  const { inviteCreator, loadingInviteCreator } = useBriefs();

  const toggleModal = () => {
    setState((prev) => ({ modalOpen: !prev.modalOpen, submitted: false }));
  };

  const onSubmit = (values: InviteCreatorValues) => {
    inviteCreator({
      accountId: values.account.value,
      briefId: values.brief.value,
      creatorEmail,
      onSuccess: () => {
        setState((prev) => ({ ...prev, submitted: true }));
      },
    });
  };

  return (
    <>
      <Tooltip content={CREATOR_COPY.TOOLTIP_REJECTED_CREATOR_INVITE} disabled={!disabled}>
        <Button
          variant={BUTTON_VARIANTS.PRIMARY_SMALL}
          css={styles}
          className={cs('creator-invite-form-modal__btn')}
          onClick={toggleModal}
          disabled={disabled}
          dataCy="creator-invite-form-modal__btn"
        >
          {CREATOR_COPY.BUTTON_MODAL_INVITE_CREATOR}
        </Button>
      </Tooltip>
      <Modal
        modalSize={SIZING.MEDIUM_WIDE}
        isOpen={state.modalOpen}
        onRequestClose={toggleModal}
        title={
          state.submitted ? CREATOR_COPY.MODAL_TITLE_INVITE_CREATORS_SUCCESS : CREATOR_COPY.MODAL_TITLE_INVITE_CREATORS
        }
        dataCy="creator-invite-form-modal"
        component={
          <>
            {state.submitted ? (
              <Box css={styles} className="creator-invite-form-modal">
                <Text className="creator-invite-form-modal__success-message">
                  {CREATOR_COPY.MODAL_DESCRIPTION_INVITE_CREATORS_SUCCESS}
                </Text>
                <hr className="creator-invite-form-modal__hr" />
                <Flex className="creator-invite-form-modal__btn-wrapper">
                  <Button variant={BUTTON_VARIANTS.SECONDARY_SMALL} onClick={toggleModal}>
                    Close
                  </Button>
                </Flex>
              </Box>
            ) : (
              <CreatorInviteForm
                onSubmit={onSubmit}
                creatorId={creatorId}
                onCancel={toggleModal}
                submitting={loadingInviteCreator}
              />
            )}
          </>
        }
      />
    </>
  );
};

export default CreatorInviteFormModal;
