import { ValueAccessorMap, ValueAccessorMethods } from 'shared/components/molecules/FilterForm/FilterForm';

import { VettingStatus } from 'shared/typings/user/enums';

import { ExperienceValue, FilterOption, GenderValue, RecentlyActiveValue, SearchBarState } from './types';

export const otherOption: { label: string; value: any; selected: boolean } = {
  label: 'Other',
  value: 'other',
  selected: false,
};

// with default active
export const recentlyActiveOptionsDefaultActive: FilterOption<RecentlyActiveValue>[] = [
  {
    label: 'All Time',
    value: '',
    selected: false,
  },
  {
    label: 'Past 6 months',
    value: '6 months',
    selected: false,
  },
  {
    label: 'Past 60 days',
    value: '60 days',
    selected: true,
  },
];

const recentlyActiveOptions: FilterOption<RecentlyActiveValue>[] = [
  {
    label: 'All Time',
    value: '',
    selected: false,
  },
  {
    label: 'Past 6 months',
    value: '6 months',
    selected: false,
  },
  {
    label: 'Past 60 days',
    value: '60 days',
    selected: false,
  },
];

export const vettingOptions: FilterOption<VettingStatus>[] = [
  {
    label: 'New',
    value: VettingStatus.NEW,
    selected: false,
  },
  {
    label: 'Pending',
    value: VettingStatus.PENDING,
    selected: false,
  },
  {
    label: 'Approved',
    value: VettingStatus.APPROVED,
    selected: false,
  },
  {
    label: 'Rejected',
    value: VettingStatus.REJECTED,
    selected: false,
  },
];

const genderOptions: FilterOption<GenderValue>[] = [
  {
    label: 'Male',
    value: 'male',
    selected: false,
  },
  {
    label: 'Female',
    value: 'female',
    selected: false,
  },
  {
    label: 'Non-binary',
    value: 'non-binary',
    selected: false,
  },
  { ...otherOption },
];

const experienceOptions: FilterOption<ExperienceValue>[] = [
  {
    label: 'Brand Approved',
    value: 'approvedAsset',
    selected: false,
  },
  {
    label: 'Elite Creator',
    value: 'eliteCreator',
    selected: false,
  },
];

const ageTagOptions: FilterOption<string>[] = [
  {
    label: '18-24',
    value: '18-24',
    selected: false,
  },
  {
    label: '25-34',
    value: '25-34',
    selected: false,
  },
  {
    label: '35-44',
    value: '35-44',
    selected: false,
  },
  {
    label: '45-59',
    value: '45-59',
    selected: false,
  },
  {
    label: '60+',
    value: '60+',
    selected: false,
  },
];

const multiSelectButtonGroupAccessors: (prefix?: string, onlyOther?: boolean) => ValueAccessorMethods = (
  prefix = '',
  onlyOther: boolean = false,
) => ({
  getDisplay: (val: FilterOption[]): string[] => {
    return val
      .filter((option) => option.selected)
      .map(({ label }) => {
        if (onlyOther) return label === 'Other' ? `${prefix}${label}` : label;
        else {
          return `${prefix}${label}`;
        }
      });
  },
  resetValue: (display: string, initialValue: FilterOption[], values: FilterOption[]) => {
    return initialValue.map((val) => {
      const displayLabel = prefix ? display.replace(prefix, '') : display;
      if (val.label === displayLabel) {
        return initialValue.find((v) => v.label === displayLabel);
      }
      return values.find((v) => v.label === val.label);
    });
  },
});

export const valueAccessorMap: ValueAccessorMap = {
  recentlyActive: {
    getDisplay: (val: FilterOption[]): string => {
      const selected = val.find((option) => option.selected);
      if (!selected) return '';
      return 'Active: ' + selected.label;
    },
    resetValue: (display: string) => '',
  },
  vettingStatus: {
    getDisplay: (val: FilterOption[]): string => {
      const selected = val.find((option) => option.selected);
      if (!selected) return '';
      return 'Vetting: ' + selected.label;
    },
    resetValue: (display: string) => '',
  },
  gender: multiSelectButtonGroupAccessors(),
  experience: multiSelectButtonGroupAccessors(),
  industry: multiSelectButtonGroupAccessors(),
  niche: multiSelectButtonGroupAccessors(),
  filmingBackdrop: multiSelectButtonGroupAccessors(),
  model: multiSelectButtonGroupAccessors(),
  ethnicity: multiSelectButtonGroupAccessors('Ethnicity: ', true),
  language: multiSelectButtonGroupAccessors('Language: ', true),
  ageTags: multiSelectButtonGroupAccessors('Age: '),
  ageRange: {
    getDisplay: (val: number[]) => {
      return `Age: ${val.join(' - ')}`;
    },
    resetValue: (display: string, initialValue: number[]) => {
      return initialValue;
    },
  },
  height: {
    getDisplay: (val: number[]) => {
      return `Height: ${val.map((v) => heightValueDisplayMap[v]).join(' - ')}`;
    },
    resetValue: (display: string, initialValue: number[]) => {
      return initialValue;
    },
  },
};

export const ageRangeLimits = {
  min: 18,
  max: 61,
};

export const heightRangeLimits = {
  min: 0,
  max: 19,
};

// corresponds to HeightRank in CreatorTags on the BE
export const heightValueDisplayMap: Record<number, string> = {
  [0]: 'under 5\'0"',
  [1]: '5\'0"',
  [2]: '5\'1"',
  [3]: '5\'2"',
  [4]: '5\'3"',
  [5]: '5\'4"',
  [6]: '5\'5"',
  [7]: '5\'6"',
  [8]: '5\'7"',
  [9]: '5\'8"',
  [10]: '5\'9"',
  [11]: '5\'10"',
  [12]: '5\'11"',
  [13]: '6\'0"',
  [14]: '6\'1"',
  [15]: '6\'2"',
  [16]: '6\'3"',
  [17]: '6\'4"',
  [18]: '6\'5"',
  [19]: 'above 6\'5"',
};

export const initialSearchBarState: Pick<
  SearchBarState,
  'recentlyActive' | 'vettingStatus' | 'experience' | 'gender' | 'search' | 'ageRange' | 'ageTags' | 'height'
> = Object.freeze({
  recentlyActive: recentlyActiveOptions,
  vettingStatus: vettingOptions,
  gender: genderOptions,
  experience: experienceOptions,
  search: '',
  ageRange: [ageRangeLimits.min, ageRangeLimits.max],
  ageTags: ageTagOptions,
  height: [heightRangeLimits.min, heightRangeLimits.max],
});
