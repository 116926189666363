import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

import { Address } from 'shared/typings/address';
import { BrkfstUser, Phone } from 'shared/typings/user';

type UserState = BrkfstUser;

type UserAction<T = UserState> = PayloadAction<{ data: T }>;

const userSlice = createSlice<UserState, SliceCaseReducers<UserState>, 'user'>({
  name: 'user',
  initialState: <BrkfstUser>{},
  reducers: {
    userLoaded: (user, action: UserAction) => action.payload.data,
    userCleared: (user) => <BrkfstUser>{},
    // @ts-ignore
    userChanged: (user, action: UserAction) => ({ ...user, ...action.payload.data }),
    userAccessRejected: () => <BrkfstUser>{},
    profilePictureUploaded: (user, action: UserAction<Pick<BrkfstUser, 'profilePic' | 'profilePicId'>>) => {
      user.profilePic = action.payload.data.profilePic;
      user.profilePicId = action.payload.data.profilePicId;
    },
    profilePictureRemoved: (user) => {
      user.profilePic = undefined;
      user.profilePicId = undefined;
    },
    userTagsUpdated: (user, action: UserAction<Pick<BrkfstUser, 'creatorTags'>>) => {
      user.creatorTags = action.payload.data.creatorTags;
    },
    addressLoaded: (user, action: UserAction<BrkfstUser>) => {
      user.mailingAddress = action.payload.data.mailingAddress;
      user.phone = action.payload.data.phone;
    },
    phoneUpdated: (user, action: UserAction<Phone>) => {
      user.phone = {
        ...user.phone,
        ...action.payload.data,
      };
    },
    addressUpdated: (user, action: UserAction<Address>) => {
      user.mailingAddress = {
        ...user.mailingAddress,
        ...action.payload.data,
      };
    },
    addressAccessRejected: (user) => {
      if (Object.keys(user).length) {
        user.mailingAddress = <Address>{};
      }
    },
    instagramDisconnected: (user) => {
      user.instagramProfile = null;
    },
    tiktokDisconnected: (user) => {
      user.tiktokProfile = null;
    },
  },
});

export const {
  userLoaded,
  userChanged,
  userCleared,
  userAccessRejected,
  profilePictureUploaded,
  profilePictureRemoved,
  userTagsUpdated,
  addressLoaded,
  phoneUpdated,
  addressUpdated,
  addressAccessRejected,
  instagramDisconnected,
  tiktokDisconnected,
} = userSlice.actions;

const reducer = userSlice.reducer;

export const getUser = (state) => state.user;

export default reducer;
