import React, { useState } from 'react';
import PhotoAlbum from 'react-photo-album';
import { faRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import GalleryTile from 'features/media/components/molecules/GalleryTile';
import { GalleryItem, UpdateSelectedItems } from 'features/media/interfaces/gallery';
import { Flex, Text } from 'shared/components/display';
import { Box } from 'shared/components/display';
import { CarouselItem } from 'shared/components/molecules/CarouselItem';
import { Lightbox } from 'shared/components/organisms/Lightbox';

import { FILE_COPY } from 'shared/config/copy';
import { noOp } from 'shared/defaults';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { BrkfstFile } from 'shared/typings/file';

import { MAX_HEIGHT, ROW_CONSTRAINTS, TARGET_HEIGHT } from './config';
import { CustomContainer } from './CustomContainer';
import { CustomRowContainer } from './CustomRowContainer';
import LoadingSkeleton from './LoadingSkeleton';
import getStyles from './styles';
import { CustomPhoto, RenderFunc } from './types';
import { getPhotosAndSkeletons, generateSkeletonObj, getRenderDimension } from './util';

const { H4 } = TEXT_VARIANTS;
interface Props {
  ariaLabelledBy?: string;
  isSelectable?: boolean;
  items: GalleryItem[];
  LightboxCaption?: React.FC<any>;
  maxHeight?: number;
  numberOfSkeletons?: number;
  numLoadingItems?: number;
  onlyImage?: boolean;
  placeholderText?: string;
  selectedItems?: number[];
  targetHeight?: number;
  updateSelectedItems?: UpdateSelectedItems;
  renderLightbox?: boolean;
  infoModalDisabled?: boolean;
  hideAspectRatio?: boolean;
  useBrandName?: boolean;
  withShowMore?: boolean;
  onShowMore?: () => void;
  showMoreCopy?: string;
  showPin?: boolean;
}

const Gallery: React.FC<Props> = ({
  items = [],
  selectedItems = [],
  updateSelectedItems = noOp,
  numberOfSkeletons = 9,
  maxHeight = MAX_HEIGHT,
  targetHeight = TARGET_HEIGHT,
  ariaLabelledBy = '',
  placeholderText = '',
  numLoadingItems = 0,
  LightboxCaption = undefined,
  onlyImage = false,
  isSelectable = false,
  renderLightbox = true,
  infoModalDisabled = false,
  hideAspectRatio = false,
  useBrandName = false,
  withShowMore = false,
  onShowMore = noOp,
  showMoreCopy = '',
  showPin = false,
}) => {
  const [lightbox, setLightbox] = useState<{ open: boolean; index: number }>({
    open: false,
    index: 0,
  });

  const toggleSelect = (item: BrkfstFile): void => {
    if (selectedItems.includes(item.id)) {
      updateSelectedItems(selectedItems.filter((id) => id !== item.id));
    } else {
      updateSelectedItems([...selectedItems, item.id]);
    }
  };
  const renderItems: RenderFunc = ({ layout, layoutOptions, photo }) => {
    const { isShowMore, isSkeleton, itemObject, src, OverlayLeft, options, selectDisabled, selectTooltip } = photo;

    const { renderHeight, renderWidth } = getRenderDimension({
      numItems: layout.photosCount,
      width: layout.width,
      height: layout.height,
      withShowMore,
      maxHeight,
    });

    const updatedLayout = { ...layout, width: renderWidth, height: renderHeight };

    if (isShowMore) {
      return (
        <Flex className="gallery__view-all-btn" height={renderHeight} onClick={onShowMore}>
          <FontAwesomeIcon icon={faRight} className="gallery__view-all-icon" />
          <Text className="gallery__view-all-text" variant={TEXT_VARIANTS.SUBHEADING}>
            {showMoreCopy}
          </Text>
        </Flex>
      );
    }
    return isSkeleton ? (
      <LoadingSkeleton layout={updatedLayout} layoutOptions={layoutOptions} maxHeight={maxHeight} />
    ) : (
      <GalleryTile
        hideAspectRatio={hideAspectRatio}
        infoModalDisabled={infoModalDisabled}
        isSelectable={isSelectable}
        isSelected={selectedItems.includes(itemObject.id)}
        itemObject={itemObject}
        key={itemObject.thumbnailUrl}
        layout={updatedLayout}
        margin={layoutOptions.spacing}
        onlyImage={onlyImage}
        openLightbox={renderLightbox ? (index: number) => setLightbox({ open: true, index }) : undefined}
        options={options}
        OverlayLeft={OverlayLeft}
        selectDisabled={selectDisabled}
        selectTooltip={selectTooltip}
        showPin={showPin}
        showSelectOption={!!selectedItems.length}
        src={src}
        toggleSelect={() => toggleSelect(itemObject)}
        useBrandName={useBrandName}
      />
    );
  };

  return items ? (
    <Box css={getStyles(withShowMore)} aria-labelledby={ariaLabelledBy} className="gallery">
      {items.length || numLoadingItems ? (
        <>
          <PhotoAlbum
            photos={getPhotosAndSkeletons(items, numLoadingItems, withShowMore)}
            targetRowHeight={targetHeight}
            layout="rows"
            spacing={2}
            renderPhoto={renderItems}
            renderContainer={CustomContainer}
            renderRowContainer={CustomRowContainer}
            rowConstraints={ROW_CONSTRAINTS}
          />
          {renderLightbox && (
            <Lightbox
              isOpen={lightbox.open}
              selectedItemIndex={lightbox.index}
              ariaLabel="Gallery Media Preview"
              onRequestClose={() => {
                setLightbox((prev) => ({ ...prev, open: false }));
              }}
            >
              {items.map(({ itemObject, captionData }) => (
                <CarouselItem
                  key={itemObject.id}
                  url={itemObject.url}
                  thumbnailUrl={itemObject.thumbnailUrl}
                  mimeType={itemObject.mimeType}
                  metadata={itemObject.metadata}
                  extension={itemObject.extension}
                  name={itemObject.name}
                  Footer={LightboxCaption ? <LightboxCaption captionData={captionData} /> : <></>}
                  useIframe={itemObject.renderInIframe}
                />
              ))}
            </Lightbox>
          )}
        </>
      ) : (
        <Text variant={H4} className="gallery__placeholder">
          {placeholderText || FILE_COPY.PLACEHOLDER_GALLERY}
        </Text>
      )}
    </Box>
  ) : (
    <Box aria-labelledby={ariaLabelledBy}>
      {/* TODO: Fix Types */}
      {/* @ts-ignore */}
      <PhotoAlbum
        photos={generateSkeletonObj(numberOfSkeletons) as CustomPhoto[]}
        targetRowHeight={targetHeight}
        // @ts-ignore
        renderPhoto={renderSkeleton}
      />
    </Box>
  );
};

export default Gallery;
