export const CREATOR_COPY = {
  // Button text
  BUTTON_APPROVE: 'Approve',
  BUTTON_REJECT: 'Reject',
  OPEN_IN_INSTAGRAM: 'Open In Instagram',
  OPEN_IN_TIKTOK: 'Open In TikTok',
  BUTTON_INVITE_CREATORS: 'Invite Creators',
  BUTTON_ALL_CREATORS: 'View All Creators',
  BUTTON_BACK_TO_PROFILE: 'Back to Creator Profile',
  BUTTON_BACK_TO_EARNINGS: 'Back to Earnings',
  BUTTON_ACTIVATE_ELITE: 'Activate Elite Status',
  BUTTON_DEACTIVATE_ELITE: 'Deactivate Elite Status',
  BUTTON_MODAL_INVITE_CREATOR: 'Invite to Apply',
  BUTTON_SUBMIT_INVITE: 'Invite Creator',

  // Modal
  MODAL_APPROVE: "By approving, you will grant this creator permission to submit a response to this account's briefs",
  MODAL_REJECT: "Are you sure you want to deny this creator permission to submit a response to this account's briefs?",

  // Body
  BODY_CREATOR_COUNT: 'Showing {displayCount} creator(s) out of {totalCount} total',

  // Labels
  LABEL_IG_ENGAGEMENT_RATE: 'Engagement',
  LABEL_IG_FOLLOWERS: 'Followers',
  LABEL_CONNECT_IG: 'Instagram',
  LABEL_CONNECT_TIKTOK: 'TikTok',
  LABEL_CREATOR_EMAIL: 'Email',
  LABEL_CREATOR_DOB: 'Date of Birth',
  LABEL_CREATOR_GENDER: 'Gender',
  LABEL_CREATOR_HEIGHT: 'Height',
  LABEL_CONNECT_STRIPE: 'Stripe',
  LABEL_MAILING_ADDRESS: 'Mailing Address',
  LABEL_PORTFOLIO_LINK: 'Portfolio Link',
  LABEL_CREATORS: 'Creators',
  LABEL_NEW_CREATOR_EMAILS: 'New Creator Emails',
  LABEL_AUTO_APPROVE_CREATORS: 'Auto-approve Creators',
  LABEL_CREATOR_NAME: 'Name',
  LABEL_CREATOR_PHONE: 'Phone',
  LABEL_PHONE_NOTIFICATIONS: 'Yes, I want to stay updated',
  LABEL_CREATOR_MESSAGE: 'Creator Message',
  LABEL_COPY_REVIEW_LINK: 'Copy Creator Review Link',
  LABEL_OTHER_NOTE: 'Note: ',
  LABEL_CREATOR_EMAIL_READONLY: 'Your Email',

  // Placeholders
  PLACEHOLDER_NO_INSTAGRAM: 'This user has not connected an Instagram account yet',
  PLACEHOLDER_CONNECT_INSTAGRAM: 'You have not connected an Instagram account yet',
  PLACEHOLDER_NO_TIKTOK: 'This user has not connected an TikTok account yet',
  PLACEHOLDER_CONNECT_TIKTOK: 'You have not connected an TikTok account yet',
  PLACEHOLDER_NO_IG_POSTS: 'No posts to display',
  PLACEHOLDER_NO_TT_VIDEOS: 'No videos to display',
  PLACEHOLDER_PORTFOLIO_URL_LINK: 'Portfolio Website Link',
  PLACEHOLDER_INVITED_CREATOR_NOT_ACCEPTED: 'This creator has not accepted your invitation yet.',
  PLACEHOLDER_TIKTOK: 'Tik Tok',
  PLACEHOLDER_CREATOR_PERFORMANCE: 'None of your creatives have been used in an ad yet.',
  PLACEHOLDER_INTERNAL_NOTE: 'No internal note saved on this creator yet',
  PLACEHOLDER_SUMMARY: 'Write a short bio about yourself',
  PLACEHOLDER_SHOWCASE: 'No approved assets to display',

  PROMPT_TEXT_ONBOARDING_SIGN_OUT: 'Onboarding progress will not be saved upon signing out',
  PROMPT_TITLE_ONBOARDING_SIGN_OUT: 'Sign Out?',
  PROMPT_BUTTON_CONFIRM_SIGN_OUT: 'Yes, sign out',

  // Header
  HEADER_IG_POSTS: 'Latest Instagram Posts',
  HEADER_TT_VIDEOS: 'Latest TikTok Posts',
  HEADER_APPROVED_ASSETS: 'Showcase',
  HEADER_SOCIAL_MEDIA_LINKS: 'Where else can we find you online?',
  HEADER_CONNECT_STRIPE: 'Get paid through Stripe',
  HEADER_PORTFOLIO_SHOWCASE: 'Showcase your portfolio',
  HEADER_TEXT_NOTIFICATIONS: 'Never miss a message or important notification',
  HEADER_CREATOR_ADDRESS: 'Where do we ship products to?',
  HEADER_PHONE_NOTIFICATIONS: 'Text Notifications',
  HEADER_REFERRAL_ONBOARDING: 'How did you hear about us?',
  HEADER_WORK_PREFERENCES: 'What type of work do you prefer?',
  HEADER_AGE_ONBOARDING: 'How old are you?',
  HEADER_GENDER_ONBOARDING: 'What is your gender?',
  HEADER_HEIGHT_ONBOARDING: 'What is your height?',
  HEADER_LANGUAGE_ONBOARDING: 'What languages do you speak?',
  HEADER_MODEL_ONBOARDING: 'Can you film any of these models?',
  HEADER_LOCATION_ONBOARDING: 'Can you film with any of these backdrops?',
  HEADER_ETHNICITY_ONBOARDING: 'What is your ethnicity?',

  // Links
  LINK_CONNECT_INSTAGRAM: 'Connect Instagram',
  LINK_PORTFOLIO_INSTAGRAM: 'View IG posts on profile',
  LINK_CONNECT_TIKTOK: 'Connect TikTok',
  LINK_PORTFOLIO_TIKTOK: 'View TikTok videos on profile',
  LINK_APPROVED_ASSETS: 'View All Creatives',

  // Captions
  CAPTION_MULTIPLE_OPTIONS: 'You can select as many options as you want',
  CAPTION_AGE: 'Your age will only be displayed on your creator profile',
  CAPTION_WORK_PREFERENCES_EDITING: 'Please select whether you edit your own content or whether you need an editor',
  CAPTION_ONE_OR_BOTH: 'You can select one or both',
  CAPTION_CONFIRM_DETAILS: "Let's just make sure we've got your details right.",
  CAPTION_CONFIRMATION:
    'Please take a moment to review and confirm your account information. This ensures that all communications and matching opportunities are able to reach you.',
  CAPTION_MODELS_NOTE:
    'The age will only be displayed on your creator profile. This information helps us match content creators with brands looking to create content that includes children of specific age groups.',

  // Form
  FORM_WARNING_PORTFOLIO_ONBOARDING: 'You need to fill at least one of the fields above in order to continue.',
  FORM_HEADING_INVITE_CREATORS: 'Invite Creators',
  VALIDATION_INVALID_CREATOR_INVITEES: `Cannot invite existing brief creators or invitees{emailCount, plural, 
    =0 {.}
    other {: {emails}}
  }`,
  VALIDATION_INVALID_MARKETER_INVITEES: `Cannot invite marketers to brief{emailCount, plural, 
    =0 {.}
    other {: {emails}}
  }`,

  // Columns
  COLUMN_CREATOR: 'Creator',
  COLUMN_CREATOR_APPLIED_DATE: 'Applied Date',
  COLUMN_CREATOR_BRIEF_STATUS: 'Status',
  COLUMN_BRIEF_DEADLINE: 'Deadline',
  COLUMN_SHIPPING_LINK: 'Tracking',

  // Performance TableColumns
  COLUMN_CREATIVE_FILE: 'File',
  COLUMN_CREATIVE_PAYOUT: 'Payout',
  COLUMN_CREATIVE_BRAND: 'Brand',
  COLUMN_CREATIVE_BRIEF: 'Brief',

  // Creator Search
  CREATOR_SEARCH: 'Find your creative match',
  NO_CREATOR_RESULTS: 'No creators matching that search', // TODO get approval from Will All Mighty

  // Tooltip

  TOOLTIP_FULL_BRIEF: 'Resume brief to approve creators',
  TOOLTIP_ELITE_CREATOR: 'Elite Creator',
  TOOLTIP_REJECTED_CREATOR_INVITE: 'This creator was rejected during vetting so cannot work on a brief.',

  // Toast
  SUCCESS_ELITE_STATUS: 'Successfully updated elite status',

  //Starred
  CREATOR_STARRED: 'Starred',

  MODAL_TITLE_INVITE_CREATORS: 'Invite to Apply',
  MODAL_TITLE_INVITE_CREATORS_SUCCESS: 'The creator has been invited!',
  MODAL_DESCRIPTION_INVITE_CREATORS:
    'Select the {selection, select, org {organization & account} account {account} other {account}} associated with the brief you want the creator to apply to.',
  MODAL_DESCRIPTION_INVITE_CREATORS_SUCCESS:
    'Invitation sent successfully! The creator now has 7 days to respond to your invitation.',
  MODAL_INFO_INVITE_CREATORS: 'You can still review and approve or reject their applications once submitted.',
  MODAL_BRIEF_DESCRIPTION_INVITE_CREATORS: 'Select an open brief to notify this creator of their invitation to apply',
};
