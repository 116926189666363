import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { Button, Text } from 'rebass/styled-components';
import { faArrowDown, faArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import { Field } from 'formik';
import shortid from 'shortid';

import ModuleScenesForm, {
  createScene,
} from 'features/briefs/components/BriefForm/components/ModuleScenesForm/ModuleScenesForm';
import { BriefModuleValues } from 'features/briefs/components/BriefForm/types';
import { MIN_SCENES } from 'features/briefs/components/BriefForm/utils';
import { Flex } from 'shared/components/display';
import Heading from 'shared/components/molecules/Heading';
import RadioGroupInput from 'shared/components/molecules/RadioGroupInput';
import { CategoryTagInput } from 'shared/components/molecules/TagInput';
import { RICHTEXT_DEFAULT_VALUE, RichtextInput } from 'shared/components/Richtext';

import { BRIEF_COPY } from 'shared/config/copy';
import {
  AD_SUPPORTED_GIF_FORMATS,
  AD_SUPPORTED_IMAGE_FORMATS,
  AD_SUPPORTED_VIDEO_FORMATS,
  formatFileTypes,
} from 'shared/config/fileFormats';
import { RECOMMENDED_SLIDER_MAX, RECOMMENDED_SLIDER_MIN } from 'shared/config/validations';
import { makeArray } from 'shared/lib/formik';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { ModuleFileType } from 'shared/typings/briefs';

import BriefDuration from '../BriefDuration';

import styles from './styles';

export const MAX_MODULES = 10;
const MIN_MODULES = 1;

const CONTENT = 'content';
const TAGS = 'tags';
const DURATION_RANGE = 'durationRange';
const FILE_TYPE = 'fileType';

export const moduleDefaults: BriefModuleValues = {
  content: RICHTEXT_DEFAULT_VALUE,
  tags: [],
  name: '',
  fileType: 'video',
  durationRange: [RECOMMENDED_SLIDER_MIN, RECOMMENDED_SLIDER_MAX],
  scenes: [],
};

const ACCEPTED_MEDIA_EXAMPLES = {
  video: formatFileTypes(AD_SUPPORTED_VIDEO_FORMATS),
  gif: formatFileTypes(AD_SUPPORTED_GIF_FORMATS),
  image: formatFileTypes(AD_SUPPORTED_IMAGE_FORMATS),
};

export const FILE_TYPE_OPTIONS: { value: ModuleFileType; label: string }[] = [
  { value: 'video', label: `Video (${ACCEPTED_MEDIA_EXAMPLES.video})` },
  { value: 'gif', label: `GIF (${ACCEPTED_MEDIA_EXAMPLES.gif})` },
  { value: 'image', label: `Image (${ACCEPTED_MEDIA_EXAMPLES.image})` },
];

interface BriefModulesFormProps {
  isDraftState: boolean;
}

export const BriefModulesForm = makeArray<BriefModuleValues, BriefModulesFormProps>(
  ({ values: modules, name, isDraftState, handlePush, handleRemove, move }) => {
    const { formatMessage } = useIntl();
    const createModule = (): BriefModuleValues => ({
      ...moduleDefaults,
      name: shortid.generate(),
    });

    // the operation is flipped over because visually the modules are arranged vertically
    const moveToNextPosition = (index) => {
      if (index < modules.length - 1) {
        move(index, index + 1);
      }
    };
    const moveToPreviousPosition = (index) => {
      if (index > 0) {
        move(index, index - 1);
      }
    };

    return (
      <Flex css={styles} className="brief-modules-form">
        {modules?.map((mod, idx) => (
          <Fragment key={`${mod.name}-${idx}`}>
            <Flex className="brief-modules-form__module-label-wrapper">
              <Text variant={TEXT_VARIANTS.SUBHEADING}>
                {formatMessage({ id: 'FORM_MODULE_DISPLAY' }, { num: idx + 1 })}
              </Text>
              <Flex className="brief-modules-form__options-wrapper">
                {isDraftState && (
                  <Flex className="brief-modules-form__order-icons-wrapper">
                    <FontAwesomeIcon
                      className={cs('brief-modules-form__order-icon-up', {
                        'brief-modules-form__order-icon--disabled': idx === 0,
                      })}
                      icon={faArrowUp}
                      size="xs"
                      color={idx > 0 ? COLORS.BLACK : COLORS.NEUTRAL200}
                      onClick={() => moveToPreviousPosition(idx)}
                    />
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      size="xs"
                      color={idx < modules.length - 1 ? COLORS.BLACK : COLORS.NEUTRAL200}
                      onClick={() => moveToNextPosition(idx)}
                      className={cs('brief-modules-form__order-icon-down', {
                        'brief-modules-form__order-icon--disabled': idx === modules.length - 1,
                      })}
                    />
                  </Flex>
                )}

                {isDraftState && (
                  <Button
                    type="button"
                    variant={BUTTON_VARIANTS.TEXT_ONLY}
                    className="brief-modules-form__remove-btn"
                    disabled={!isDraftState || modules.length <= MIN_MODULES}
                    onClick={handleRemove(idx)}
                  >
                    Remove Module
                  </Button>
                )}
              </Flex>
            </Flex>
            <Flex className="brief-modules-form__module">
              <Field
                name={`${name}[${idx}].${CONTENT}`}
                component={RichtextInput}
                placeholder={BRIEF_COPY.FORM_PLACEHOLDER_MODULE_DESCRIPTION}
                readableWidth
              />
              <Field
                name={`${name}[${idx}].${TAGS}`}
                component={CategoryTagInput}
                className="module-tags"
                placeholder={BRIEF_COPY.FORM_PLACEHOLDER_MODULE_TAGS}
                categoryRequired
              />
              <Heading
                text={BRIEF_COPY.FORM_HEADING_FILE_TYPE}
                variant={TEXT_VARIANTS.H5}
                className="brief-form__section-header"
              />
              <Field
                name={`${name}[${idx}].${FILE_TYPE}`}
                component={RadioGroupInput}
                options={FILE_TYPE_OPTIONS}
                disabled={!isDraftState}
              />
              {mod.fileType !== 'image' && (
                <Field name={`${name}[${idx}].${DURATION_RANGE}`} component={BriefDuration} />
              )}
              <ModuleScenesForm moduleIndex={idx} name={`${name}[${idx}].scenes`} />
            </Flex>
          </Fragment>
        ))}
        {isDraftState && (
          <Button
            type="button"
            className="brief-modules-form__add-btn"
            disabled={!isDraftState || modules.length >= MAX_MODULES}
            onClick={handlePush(createModule())}
          >
            Add Module
          </Button>
        )}
      </Flex>
    );
  },
);
