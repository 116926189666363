import { capitalize } from 'lodash';
import { getDomain, tldExists } from 'tldjs';

import { CONSTRAINTS } from 'shared/config/validations';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const isNumber = (value) => !isNaN(parseFloat(value)) && isFinite(value);
export const formatCurrency = (value) => (isNumber(value) ? formatter.format(value) : '');

export const addThousandsSeparators = (val) => {
  return val.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
};

export const formatStringPercent = (val) => {
  return `${parseFloat(val).toFixed(2)}%`;
};

export const formatLargeNumbers = (number) => {
  const NUMBERS = {
    MILLION: 1000000,
    THOUSAND: 1000,
  };
  if (number >= NUMBERS.MILLION) {
    return `${(number / NUMBERS.MILLION).toFixed(1)}M`;
  }
  if (number >= NUMBERS.THOUSAND) {
    return `${(number / NUMBERS.THOUSAND).toFixed(1)}k`;
  }
  return number;
};

export const isHashedId = (val) => {
  const hexPattern = /[0-9A-Fa-f]{6}/g;
  return hexPattern.test(val);
};

/**
 * Given a duration in seconds, formats it into mm:ss
 */
export const formatDurationSeconds = (duration) => {
  const date = new Date(0);
  date.setSeconds(duration);
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  return seconds < 10 ? `${minutes}:0${seconds}` : `${minutes}:${seconds}`;
};

export const arrayIncludesString = (list, value) => {
  return list.some((element) => value.includes(element));
};

export const isNumeric = (num) => {
  /* eslint-disable no-restricted-globals */
  return !isNaN(parseFloat(num)) && !isNaN(num - 0);
  /* eslint-enable no-restricted-globals */
};

export const testRegexpMatch = (regexp, value) => {
  const matchPathRegexp = new RegExp(regexp);
  return matchPathRegexp.test(value);
};

export const humanize = (value) => {
  return capitalize(value.replace(/_|(?=\D)-(?=\D)/g, ' '));
};

const isOnlyDomain = (domain) => getDomain(domain) === domain;
export const isValidFBAdDomain = (domain) => isOnlyDomain(domain) && tldExists(domain);

export const isValidDomain = (domain) => CONSTRAINTS.DOMAIN_CONSTRAINT.test(domain) && tldExists(domain);

export const hasSpecialCharacters = (domain) => CONSTRAINTS.DOMAIN_CHARACTERS_CONSTRAINT.test(domain);

export const addHttp = (url, secure = false) => {
  const suffix = secure ? 's' : '';
  return !/^(?:f|ht)tps?:\/\//.test(url) ? `http${suffix}://${url}` : url;
};

export const hasHttpPrefix = (url) => {
  return /https:\/\//.test(url);
};

export const charactersPresentBeforeHttp = (link: string): boolean => {
  return /.+http(s)*:\/\//.test(link);
};

export function removeExtensionFromFilename(filename = '') {
  const validExtensions = [
    'mp4',
    'mov',
    'm4a',
    'm4v',
    'csv',
    'jpg',
    'jpeg',
    'png',
    'tiff',
    'webp',
    'eps',
    'pdf',
    'svg',
    'gif',
  ].join('|');
  const extensionRegExp = new RegExp(`\\.(${validExtensions}$)`, 'i');
  return filename.replace(extensionRegExp, '');
}

export const removeDuplicateCharacters = (str) => {
  let result = '';
  for (let i = 0; i < str.length; i++) {
    if (result.indexOf(str[i]) < 0) {
      result += str[i];
    }
  }
  return result;
};

/**
 * Interpolate object into string of handlebar-style variables.
 *
 * @param {string} dynamicStr - String with handlebar variables. e.g: {{variable}}_str
 * @param {object} varMap - Variable to interpolate. e.g: { variable: 'dynamic' }
 */
export const parseDynamicString = (dynamicStr, varMap = {}) =>
  Object.keys(varMap)
    .map((val) => ({
      regex: new RegExp(`{{${val}}}`),
      value: varMap[val],
    }))
    .reduce((str, { regex, value }) => str.replace(regex, value), dynamicStr)
    .replace(/\{\{(.*?)\}\}/g, '');

export function copyToClipboard(str: string) {
  return navigator.clipboard.writeText(str);
}

export const capitalizeFirstLetter = (str: string) => {
  const exceptions = ['or', 'and'];
  const constCase = /^[A-Z]+$/;
  // if string is in CONST_CASE then leave as const case
  if (constCase.test(str)) {
    return str;
  }
  // Handles special cases where the string has punctuation
  if (str.includes('/')) {
    return str
      .split('/')
      .map((word) => capitalize(word))
      .join('/');
  }
  if (str[0] === "'") {
    return str[0] + capitalize(str.slice(1));
  }
  if (str.includes('-')) {
    return str
      .split('-')
      .map((word) => {
        if (!exceptions.includes(word)) {
          return capitalize(word);
        }
        return word;
      })
      .join('-');
  }
  if (str.includes('&')) {
    return str
      .split('&')
      .map((word) => capitalize(word))
      .join('&');
  }
  return capitalize(str);
};

export const capitalizeWithoutConjunctions = (str: string) => {
  const exceptions = ['or', 'and', 'of'];

  return str
    .split(' ')
    .map((word, index) => {
      if (index === 0 || !exceptions.includes(word.toLowerCase())) {
        return capitalizeFirstLetter(word);
      }
      return word.toLowerCase();
    })
    .join(' ');
};
