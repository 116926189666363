export default {
  ASSETS_LIBRARY: '/library',

  CREATOR_COLLECTION: '/library/:collectionId',

  EARNINGS: '/earnings',

  PERFORMANCE: '/performance',

  CREATE_UPDATE_SUBMISSION: '/submissions/:submissionId',

  MESSAGES: '/messaging',

  USER: '/user',

  CREATOR_ONBOARDING: '/onboarding',

  DASHBOARD: '/dashboard',

  ONBOARD_COMPLETE: '/onboarding',

  VIEW_INDIVIDUAL_BRIEF: '/briefs/:briefId',

  BRIEFS_BOARD: '/briefs-board',

  CREATOR_SUBMISSIONS: '/your-briefs',
};
