import React, { useMemo } from 'react';
import cs from 'classnames';
import { Select, SelectValue } from 'shared/components/molecules/Select';
import { useQuery } from 'shared/hooks/useQuery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import COLORS from 'shared/styles/colors';
import { faFilter } from '@fortawesome/pro-light-svg-icons';
import { FilterOptions } from './types';

interface Props {
  width?: string;
  pageParamsName?: string;
  searchParams?: any;
  filterOptions?: FilterOptions;
  filterParamsName: string;
  className?: string;
  showFilterIcon?: boolean;
  onChange: (option: SelectValue) => void;
}

export const PaginationFilter: React.FC<Props> = ({
  className,
  filterOptions = [],
  filterParamsName,
  onChange,
  pageParamsName = 'page',
  searchParams = {},
  showFilterIcon = false,
  width = '200px',
}) => {
  const { stringifyQuery } = useQuery();

  const formattedFilterOptions: SelectValue[] = useMemo(() => {
    return filterOptions.map(({ value, label }) => ({
      label,
      value: stringifyQuery({
        [filterParamsName]: value,
        [pageParamsName]: 1,
      }),
    }));
  }, [filterOptions]);

  const selectedFilterValue = useMemo(() => {
    const filterValue = stringifyQuery({
      [filterParamsName]: searchParams[filterParamsName],
      [pageParamsName]: 1,
    });

    return formattedFilterOptions.find(({ value }) => value === filterValue) || formattedFilterOptions[0];
  }, [searchParams, filterParamsName, pageParamsName]);

  return (
    <Select
      name="filter"
      options={formattedFilterOptions}
      onChange={onChange}
      value={selectedFilterValue}
      className={cs('pagination-filter', className)}
      width={width}
      menuPortalTarget="body"
      ControlPrefix={showFilterIcon ? <FontAwesomeIcon icon={faFilter} color={COLORS.PRIMARY500} /> : undefined}
      isSearchable
      hidePlaceholder
      filterByLabel
    />
  );
};
