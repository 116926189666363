import { SerializedStyles } from '@emotion/react';
import React from 'react';
import ReactModal from 'react-modal';
import { Carousel as RRCarousel } from 'react-responsive-carousel';
import { Button } from 'rebass/styled-components';

import { Box } from 'shared/components/display';
import { CarouselItemProps } from 'shared/components/molecules/CarouselItem';
import LightboxLeftArrow from 'shared/components/organisms/Lightbox/LightboxLeftArrow';
import LightboxRightArrow from 'shared/components/organisms/Lightbox/LightboxRightArrow';
import lightboxStyles, { lightboxModalCss } from 'shared/components/organisms/Lightbox/lightboxStyles';

import { BUTTON_VARIANTS } from 'shared/styles/button';

interface Props {
  ariaLabel: string;
  isOpen?: boolean;
  onRequestClose?: () => any;
  children: React.ReactElement<CarouselItemProps>[];
  selectedItemIndex?: number;
  onChange?: (index: number, item: React.ReactNode) => void;
  contentStyles?: SerializedStyles;
  dataCy?: string;
}
const Lightbox: React.FC<Props> = ({
  ariaLabel,
  isOpen,
  onRequestClose,
  children,
  selectedItemIndex,
  onChange,
  contentStyles = undefined,
  dataCy,
}) => {
  ReactModal.setAppElement('#root');

  const requestToClose = () => {
    if (onRequestClose) {
      onRequestClose();
    }
  };

  //In order for the children to know if they are selected React Responsive Carousel has to render the item in order to
  //pass in an object with the isSelected property as arguments.
  const customRenderItem = (item, props) => {
    if (item.type === React.Fragment) return <item.type {...item.props} />;
    return <item.type {...item.props} {...props} />;
  };

  return (
    <ReactModal
      onRequestClose={requestToClose}
      isOpen={isOpen}
      role={'dialog'}
      aria={{
        label: ariaLabel,
      }}
      style={lightboxModalCss}
      overlayClassName="brkfst-modal__click-overlay"
      className="brkfst-modal"
      id="modal-root" // used by Select and MultiSelect to portal the menu element in order to make it visible outside of the modal. Open issue with library: https://github.com/JedWatson/react-select/issues/4088
    >
      <Box className="lightbox" css={lightboxStyles} onClick={requestToClose} data-cy={dataCy}>
        <Box className="lightbox__header">
          <Button
            variant={BUTTON_VARIANTS.TEXT_ONLY}
            onClick={(e) => {
              e.stopPropagation();
              requestToClose();
            }}
            className="lightbox__close-btn"
            type="button"
          >
            &#10005;
          </Button>
        </Box>
        <Box className="lightbox__content" css={contentStyles}>
          <RRCarousel
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            selectedItem={selectedItemIndex}
            renderArrowPrev={(clickHandler, hasPrev) => (
              <LightboxLeftArrow clickHandler={clickHandler} hasPrev={hasPrev} />
            )}
            renderArrowNext={(clickHandler, hasNext) => (
              <LightboxRightArrow clickHandler={clickHandler} hasNext={hasNext} />
            )}
            onChange={onChange}
            useKeyboardArrows
            className="lightbox__carousel"
            renderItem={customRenderItem}
          >
            {React.Children.map<React.ReactElement<CarouselItemProps>, React.ReactElement>(children, (Child) => {
              if (Child && Child.type !== React.Fragment) {
                return React.cloneElement(Child, {
                  showThumbnail: false,
                });
              }
              return Child;
            })}
          </RRCarousel>
        </Box>
      </Box>
    </ReactModal>
  );
};

export default Lightbox;
