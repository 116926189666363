import React from 'react';
import cs from 'classnames';

import { ProfilePicture } from 'features/users/components/molecules/ProfilePicture';
import smallCreatorPreviewStyles from 'features/users/components/SmallCreatorPreview/smallCreatorPreviewStyles';
import Tooltip from 'shared/components/atoms/Tooltip/NewTooltip';
import { Flex, Text } from 'shared/components/display';

import { TEXT_VARIANTS } from 'shared/styles/text';
import { BrkfstUser } from 'shared/typings/user';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';

interface Props {
  user: Pick<BrkfstUser, 'profilePic' | 'firstName' | 'lastName' | 'id' | 'email'>;
  showFullName?: boolean;
  fixedHeight?: boolean;
  hideProfilePicture?: boolean;
  showEmail?: boolean;
  showTooltip?: boolean;
}
const SmallCreatorPreview: React.FC<Props> = ({
  user,
  showFullName = false,
  showEmail = false,
  fixedHeight = false,
  hideProfilePicture = false,
  showTooltip = false,
}) => {
  const name = showFullName ? `${user.firstName} ${user.lastName}` : user.firstName;
  const nameDisplay = showEmail ? `${name} (${user.email})` : name;

  const profileLink = LinkCreator.createLink({
    userType: UserType.CREATOR,
    routeKey: 'USER_PROFILE',
    variables: {
      userId: user.id,
    },
  });
  return (
    <Flex
      css={smallCreatorPreviewStyles}
      className={cs('creator-preview', {
        'creator-preview--fixed-height': fixedHeight,
      })}
    >
      {!hideProfilePicture && <ProfilePicture user={user} size="xs" customProfileLink={profileLink} />}
      <Tooltip content={nameDisplay} disabled={!showTooltip} portalTarget="root">
        <Text variant={TEXT_VARIANTS.BODY} className={cs('creator-preview__name', 'endWithEllipsis')}>
          {nameDisplay}
        </Text>
      </Tooltip>
    </Flex>
  );
};

export default SmallCreatorPreview;
