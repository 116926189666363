import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { Text } from 'rebass/styled-components';
import { faPencilAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';

import CreatorUserFields from 'features/users/components/CreatorUserFields/CreatorUserFields';
import EditableCreatorInfo from 'features/users/components/molecules/EditableCreatorInfo/EditableCreatorInfo';
import { ProfilePicture } from 'features/users/components/molecules/ProfilePicture';
import ProfileModalForm from 'features/users/components/ProfileModalForm/ProfileModalForm';
import ProfilePictureWithDragAndDrop from 'features/users/components/ProfilePictureWithDragAndDrop/ProfilePictureWithDragAndDrop';
import CreatorAddress from 'features/users/CreatorAddress';
import { Box } from 'shared/components/display';
import CreatorTags from 'features/users/components/molecules/CreatorTags/CreatorTags';
import { USER_COPY } from 'shared/config/copy';
import COLORS from 'shared/styles/colors';
import { breakpoints } from 'shared/styles/styleFunctions';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { BrkfstTag } from 'shared/typings/tag';
import { BrkfstUser } from 'shared/typings/user';
import { UserType } from 'shared/typings/user/enums';
import { getAgeFromDate } from 'shared/utilities/dateUtility';
import { LinkCreator } from 'shared/utilities/linkUtility';
import EditableCreatorSummary from 'features/users/components/molecules/EditableCreatorSummary';
import { useUser } from 'features/users/hooks/useUser';
import { USER_TOASTS } from 'shared/config/toasts/userToasts';

import creatorInfoPanelStyles from './creatorInfoPanelStyles';

const { NEUTRAL600 } = COLORS;

interface Props {
  user: BrkfstUser;
  creatorTags: BrkfstTag[];
}

const CreatorView: React.FC<Props> = ({ user, creatorTags }) => {
  const { updateUser } = useUser(user.id);
  const isMobile = useMediaQuery({
    maxWidth: breakpoints.md - 1,
  });

  const age =
    user.type === UserType.CREATOR
      ? user.birthdate
        ? getAgeFromDate(user.birthdate)
        : creatorTags.find((tag) => tag.category.toLowerCase() === 'age')?.value
      : null;

  // Typescript requires type validation to access BrkfstUser properties
  const gender =
    user.type === UserType.CREATOR
      ? user.gender
        ? user.gender
        : creatorTags.find((tag) => tag.category.toLowerCase() === 'gender')?.value
      : null;

  const genderAgeLabel = `${gender ? gender : ''}${gender && age ? ', ' : ''}${age?.toString() ? age : ''}`;

  const updateCreatorInfo = (values: Partial<BrkfstUser>) => {
    updateUser({
      data: {
        id: user.id,
        ...values,
      },
      successToast: {
        message: USER_TOASTS.UPDATED_USER_FIELD,
      },
    });
  };

  return (
    <Box as="section" css={creatorInfoPanelStyles} className={cs('profile-panel', 'profile-panel--white')}>
      <Box
        className={cs('profile-panel__item', 'profile-panel__profile-pic-wrapper', {
          ['profile-panel__modal-form-btn']: isMobile,
        })}
      >
        {isMobile ? (
          <>
            <ProfileModalForm userId={user.id} />
            <ProfilePicture user={user} size="lg" />
          </>
        ) : (
          <ProfilePictureWithDragAndDrop userId={user.id} isCurrentUser />
        )}
      </Box>
      <h4 className={cs('profile-panel__header', 'profile-panel__header--centered')}>
        {`${user.firstName} ${user.lastName}`}
      </h4>
      <Text className="profile-panel__gender-age" variant={TEXT_VARIANTS.BODY}>
        {genderAgeLabel}
      </Text>
      <EditableCreatorSummary summary={user.summary} className="profile-panel__item" onSubmit={updateCreatorInfo} />
      <Box className={cs('profile-panel__item', 'profile-panel__tags')}>
        <CreatorTags tags={creatorTags} />
      </Box>
      <Box className={cs('profile-panel__item')}>
        {isMobile ? (
          <CreatorUserFields user={user} />
        ) : (
          <EditableCreatorInfo user={user} onSubmit={updateCreatorInfo} />
        )}
        <CreatorAddress userId={user.id} />
        <Link
          to={LinkCreator.createLink({
            userType: UserType.CREATOR,
            routeKey: 'ACCOUNT_SETTINGS',
            variables: {
              userId: user.id,
            },
          })}
        >
          <Text color={NEUTRAL600}>
            <FontAwesomeIcon icon={faPencilAlt} size="xs" />
            &nbsp;{USER_COPY.LINK_EDIT_DETAILS}
          </Text>
        </Link>
      </Box>
    </Box>
  );
};

export default CreatorView;
