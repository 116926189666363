// Contains all application variables and default values
// The ones coming from process.env will be replaced during the build process

// The ones coming from window.env need to be declared in server/environmentVariables.js and will be pulled from the server at runtime
window.env = window.env || {};

export default {
  API_URL: window.env.API_URL || 'http://localhost:1337',
  APPLICATION_TIMEZONE: 'America/New_York',
  BLOB_CONTAINER_NAME: window.env.BLOB_CONTAINER_NAME || 'brkfst',
  BLOB_FILE_DIRECTORY: window.env.BLOB_FILE_DIRECTORY || 'files',
  BLOB_STORAGE_URL: window.env.BLOB_STORAGE_URL || 'https://storagebrkfstfndev.blob.core.windows.net',
  BRKFST_ACCOUNT_MANAGER_USER: window.env.BRKFST_ACCOUNT_MANAGER_USER || 'admin@brkfst.io',
  FB_CONNECTION_SCOPE:
    window.env.FB_CONNECTION_SCOPE ||
    'business_management,pages_read_engagement,ads_read,catalog_management,email,public_profile',
  FB_CLIENT_ID: window.env.FB_CLIENT_ID || '147485085845019',
  FB_REDIRECT_URI: window.env.FB_REDIRECT_URI || '/account/connect-client-fb-business',
  FB_OAUTH_SCOPES: window.env.FB_OAUTH_SCOPES || 'email,public_profile',
  FB_APP_VERSION: window.env.FB_APP_VERSION || 'v18.0',
  FB_CUSTOMER_FILE_DIR: window.env.FB_CUSTOMER_FILE_DR || 'facebook-customer-files',
  GOOGLE_ANALYTICS_SAMPLE_RATE: window.env.GOOGLE_ANALYTICS_SAMPLE_RATE
    ? parseInt(window.env.GOOGLE_ANALYTICS_SAMPLE_RATE, 10)
    : 100,
  GOOGLE_ANALYTICS_TRACKING_ID: window.env.GOOGLE_ANALYTICS_TRACKING_ID || 'UA-185267035-1',
  GOOGLE_API_FONT_KEY: 'AIzaSyANB7sQFY1GgiTZI5jgp24R6jxtaGzuNtU',
  HIDE_DEV_FEATURES: window.env.HIDE_DEV_FEATURES || false,
  IG_APP_ID: window.env.IG_APP_ID || 613426282751163,
  IG_CALLBACK_URL: window.env.IG_CALLBACK_URL || '/user/auth/instagram/callback',
  IG_SCOPE: 'user_profile,user_media',
  MAX_FILE_SLICE_SIZE: 50000000,
  RICHTEXT_FILE_DIRECTORY: window.env.RICHTEXT_FILE_DIRECTORY || 'rich-text-files',
  TREND_FILE_DIRECTORY: window.env.TREND_FILE_DIRECTORY || 'trend-files',
  ROLLBAR_TOKEN: window.env.ROLLBAR_TOKEN || '00b71553e6194e9e913f7622ba7fba96',
  SERVER_NAME: window.env.WEBSITE_SITE_NAME || 'local',
  SIGNALR_CONNECTION_HUB: window.env.SIGNALR_CONNECTION_HUB || 'https://func-brkfstmedia-dev.azurewebsites.net',
  STREAM_CHAT_KEY: window.env.STREAM_CHAT_KEY || process.env.REACT_APP_STREAM_CHAT_KEY || 'mc2dt9rnkzcv',
  STREAM_CHAT_TIMEOUT: window.env.STREAM_CHAT_TIMEOUT ? parseInt(window.env.STREAM_CHAT_TIMEOUT, 10) : 6000,
  STRIPE_CLIENT_ID: window.env.STRIPE_CLIENT_ID || 'ca_G4jB5vgmj4xaOuq9We13bhdMOLzby8p7',
  STRIPE_PUBLIC_KEY: window.env.STRIPE_PUBLIC_KEY || 'pk_test_Jt95LyvDu73W7HtwaDfZogFZ00kElBH6hu',
  TIKTOK_APP_ID: window.env.TIKTOK_APP_ID || '7137258987199660034',
  TIKTOK_CALLBACK_URL: window.env.TIKTOK_CALLBACK_URL || '/user/auth/tiktok/callback',
  TIKTOK_CLIENT_KEY: window.env.TIKTOK_CLIENT_KEY || 'awfthtm4yp6wjwta',
  TIKTOK_RID: window.env.TIKTOK_RID || 'tpjd0s8tp4',
  TIKTOK_REDIRECT_URI:
    window.env.TIKTOK_REDIRECT_URI || 'https://api-brkfstbe-dev.azurewebsites.net/account/connect-tiktok',
  TIKTOK_USER_SCOPES: window.env.TIKTOK_USER_SCOPES || 'user.info.basic,user.info.profile,user.info.stats,video.list',
  tokenPrefix: 'Bearer',
  tokenRefreshBuffer: 5, // refresh token this many minutes before expiration
  ENCRYPTION_KEY: window.env.ENCRYPTION_KEY || 'V2uC8oN9qgPxdaknWubQlIN8tzURofYHoxYhZEE0c2ZNPPEJnxGq3EO8wOfe2',
  FILE_NAME_LENGTH: window.env.FILE_NAME_LENGTH || 180,
  GTM_ID: window.env.GTM_ID || 'GTM-WKCBRPZ',
  GMAIL_USER: window.env.GMAIL_USER || 'hello@brkfst.io',
  CYPRESS_CREATOR_EMAIL: process.env.E2E_TEST_CREATOR_EMAIL || 'creator.qa@brkfst.io',
  CYPRESS_CREATOR_PASSWORD: process.env.E2E_TEST_CREATOR_PASSWORD || 'it-should-work@1337',
  CYPRESS_MARKETER_EMAIL: process.env.E2E_TEST_MARKETER_EMAIL || 'brand.qa@brkfst.io',
  CYPRESS_MARKETER_PASSWORD: process.env.E2E_TEST_MARKETER_PASSWORD || 'it-should-work@1337',
  CYPRESS_SUPERADMIN_EMAIL: process.env.E2E_TEST_SUPERADMIN_EMAIL || 'camtesting@brkfst.io',
  CYPRESS_SUPERADMIN_PASSWORD: process.env.E2E_TEST_SUPERADMIN_PASSWORD || 'it-should-work@1337',
  MARKETING_SITE_URL: window.env.MARKETING_SITE_URL || 'https://www.brkfst.io/improve-my-score',
  FEATURE_FLAG_TIKTOK_INTEGRATION: window.env.FEATURE_FLAG_TIKTOK_INTEGRATION
    ? JSON.parse(window.env.FEATURE_FLAG_TIKTOK_INTEGRATION)
    : false,
  FEATURE_FLAG_DELETE_USER: window.env.FEATURE_FLAG_DELETE_USER
    ? JSON.parse(window.env.FEATURE_FLAG_DELETE_USER)
    : false,
  FEATURE_FLAG_TRENDS_LIBRARY: window.env.FEATURE_FLAG_TRENDS_LIBRARY
    ? JSON.parse(window.env.FEATURE_FLAG_TRENDS_LIBRARY)
    : false,
  SURVEY_VISIBILITY_DELAY_MS: window.env.SURVEY_VISIBILITY_DELAY_MS || 10000,
};
