// fieldnames
export const LANDING_PAGE = 'landingPage';
export const HEADLINE = 'headline';
export const DESCRIPTION = 'description';
export const BODY = 'primaryText';
export const CALL_TO_ACTION = 'callToAction';
export const DISPLAY_URL = 'displayUrl';
export const AD_NAME = 'adName';
export const URL_TAGS = 'urlTags';
export const SEE_MORE_URL = 'seeMoreUrl';
export const OPTIMIZE = 'optimize';
export const ADD_END_CARD = 'addEndCard';

// Label
export const AD_NAME_PREVIEW = 'Ad Name Preview';

export const ADS = {
  FACEBOOK: {
    // https://www.facebook.com/business/ads-guide/video
    SINGLE_AD_INPUTS: {
      DYNAMIC: [
        { fieldName: LANDING_PAGE, required: true },
        { fieldName: HEADLINE, maxLength: 40, required: true },
        { fieldName: BODY, maxLength: 125, required: true },
        { fieldName: DESCRIPTION, maxLength: 30, required: false },
      ],
      ADDITIONAL: [DISPLAY_URL, AD_NAME, URL_TAGS],
      STATIC: [CALL_TO_ACTION, DISPLAY_URL, AD_NAME, URL_TAGS],
    },
    // https://www.facebook.com/business/ads-guide/carousel
    CAROUSEL_AD_INPUTS: {
      ASSET: [
        { fieldName: LANDING_PAGE, required: true },
        { fieldName: HEADLINE, maxLength: 40, required: true },
        { fieldName: DESCRIPTION, maxLength: 20, required: false },
      ],
      DYNAMIC: [{ fieldName: BODY, maxLength: 125, required: true }],
      BOOLEAN: [ADD_END_CARD, OPTIMIZE],
      ADDITIONAL: [DISPLAY_URL, SEE_MORE_URL, AD_NAME, URL_TAGS],
      STATIC: [CALL_TO_ACTION, DISPLAY_URL, SEE_MORE_URL, AD_NAME, URL_TAGS, OPTIMIZE, ADD_END_CARD],
    },
    CTA: {
      'Apply Now': 'APPLY_NOW',
      'Book Now': 'BOOK_TRAVEL',
      'Contact Us': 'CONTACT_US',
      Download: 'DOWNLOAD',
      'Get Quote': 'GET_QUOTE',
      'Get Showtimes': 'GET_SHOWTIMES',
      'Learn More': 'LEARN_MORE',
      'Listen Now': 'LISTEN_NOW',
      'No Button': 'NO_BUTTON',
      'Order Now': 'ORDER_NOW',
      'Play Game': 'PLAY_GAME',
      'Request Time': 'REQUEST_TIME',
      'Shop Now': 'SHOP_NOW',
      'Sign Up': 'SIGN_UP',
      Subscribe: 'SUBSCRIBE',
      'Watch More': 'WATCH_MORE',
    },
    // figure out if this will be used in future
    PREVIEW_TYPES: [
      'AUDIENCE_NETWORK_INSTREAM_VIDEO',
      'AUDIENCE_NETWORK_INSTREAM_VIDEO_MOBILE',
      'AUDIENCE_NETWORK_OUTSTREAM_VIDEO',
      'AUDIENCE_NETWORK_REWARDED_VIDEO',
      'DESKTOP_FEED_STANDARD',
      'FACEBOOK_STORY_MOBILE',
      'INSTAGRAM_STANDARD',
      'INSTAGRAM_STORY',
      'INSTANT_ARTICLE_STANDARD',
      'INSTREAM_VIDEO_DESKTOP',
      'INSTREAM_VIDEO_MOBILE',
      'MARKETPLACE_MOBILE',
      'MESSENGER_MOBILE_INBOX_MEDIA',
      'MOBILE_BANNER',
      'MOBILE_FEED_BASIC',
      'MOBILE_FEED_STANDARD',
      'MOBILE_FULLWIDTH',
      'MOBILE_INTERSTITIAL',
      'MOBILE_MEDIUM_RECTANGLE',
      'MOBILE_NATIVE',
      'RIGHT_COLUMN_STANDARD',
      'SUGGESTED_VIDEO_DESKTOP',
      'SUGGESTED_VIDEO_MOBILE',
      'WATCH_FEED_MOBILE',
    ],

    TYPES: {
      VIDEO: 1,
      IMAGE: 2,
      CAROUSEL: 3,
      COLLECTION: 4,
    },
  },

  AD_FORMATS: ['DESKTOP_FEED_STANDARD', 'MOBILE_FEED_STANDARD', 'INSTAGRAM_STANDARD', 'INSTAGRAM_STORY'],

  PRETTY_AD_FORMATS: {
    DESKTOP_FEED_STANDARD: 'Facebook Desktop Feed',
    MOBILE_FEED_STANDARD: 'Facebook Mobile Feed',
    INSTAGRAM_STANDARD: 'Instagram Feed',
    INSTAGRAM_STORY: 'Instagram Story',
  },

  PREVIEW_DIMENSIONS: {
    PHONE: {
      HEIGHT: 860,
      WIDTH: 460,
    },

    DESKTOP: {
      HEIGHT: 743,
      WIDTH: 502,
    },
  },

  ASPECT_RATIOS: {
    // if an instagram story image is the size of the phone screen
    // height / width will be 1.7 ish
    // otherwise, it's a story with a linear gradient behind it
    INSTAGRAM_STORY: 1.7,
    INSTAGRAM_FEED: 1.25,
  },
};
