import React, { useMemo } from 'react';
import cs from 'classnames';
import { Box, Button, Flex, Text } from 'shared/components/display';
import TrendFileInput from 'features/trends/components/molecules/TrendFileInput';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { Field, Form, Formik } from 'formik';
import { TRENDS_LIBRARY_COPY } from 'features/trends/pages/TrendsLibrary/copy';
import TextInput from 'shared/components/TextInput';
import { initialValues, getValidationSchema, TrendFormValues } from './config';
import styles from './styles';
import { useTrends } from 'features/trends/hooks/useTrends';
import CenteredSpinner from 'features/ui/CenteredSpinner';

interface Props {
  values: TrendFormValues;
  onSubmit: (values: TrendFormValues) => void;
  onCancel?: () => void;
  onDelete?: () => void;
  isNew?: boolean;
}
const TrendForm: React.FC<Props> = ({ values, onSubmit, onCancel, onDelete, isNew }) => {
  const { trends, loading } = useTrends();

  const defaultValues = useMemo(() => {
    return {
      ...initialValues,
      ...values,
    };
  }, [values]);

  const trendNames = trends.map((trend) => trend.name);

  if (loading) {
    return <CenteredSpinner />;
  }

  return (
    <Box css={styles} className={cs('trend-form')}>
      <Formik<TrendFormValues>
        initialValues={defaultValues}
        onSubmit={onSubmit}
        validationSchema={getValidationSchema(trendNames)}
      >
        <Form>
          <Text className="trend-form__subheader">{TRENDS_LIBRARY_COPY.TREND_FORM_SUB_HEADER}</Text>
          <Field name="name" placeholder="Trend Name" label="Trend Name" component={TextInput} />
          <Field name="file" component={TrendFileInput} />

          <Flex className="trend-form__button-wrapper">
            <Button type="button" className="trend-form__btn" onClick={onCancel} variant={BUTTON_VARIANTS.TOOL}>
              Cancel
            </Button>
            {!isNew && (
              <Button
                type="button"
                className={cs('trend-form__btn', 'trend-form__delete-btn')}
                onClick={onDelete}
                variant={BUTTON_VARIANTS.TOOL}
              >
                Delete
              </Button>
            )}
            <Button
              type="submit"
              className={cs('trend-form__btn', 'trend-form__submit-btn')}
              variant={BUTTON_VARIANTS.TOOL}
            >
              {isNew ? TRENDS_LIBRARY_COPY.BUTTON_CREATE_TREND : TRENDS_LIBRARY_COPY.BUTTON_EDIT_TREND}
            </Button>
          </Flex>
        </Form>
      </Formik>
    </Box>
  );
};

export default TrendForm;
