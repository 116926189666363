import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';

import { useAccounts } from 'features/accounts/hooks/useAccounts';
import {
  FIELDNAMES,
  getInviteCreatorSchema,
  initialValues,
} from 'features/briefs/components/organisms/InviteCreators/config';
import inviteCreatorsStyles from 'features/briefs/components/organisms/InviteCreators/inviteCreatorsStyles';
import { useBriefs } from 'features/briefs/hooks/useBriefs';
import { TagEmail } from 'shared/components/atoms/Tags';
import { Box, Text } from 'shared/components/display';
import { ArrayErrorMessage } from 'shared/components/FieldErrorMessage';
import FormButton from 'shared/components/FormButton';
import AsyncCreatorSelect from 'shared/components/molecules/CreatorsSelect/AsyncCreatorSelect';
import { CreatorSelectValue, FetchCreatorOptions } from 'shared/components/molecules/CreatorsSelect/interfaces';
import { TagInput } from 'shared/components/molecules/TagInput';
import ToggleInput from 'shared/components/ToggleInput';

import axiosClient from 'shared/axiosClient';
import { CREATOR_COPY } from 'shared/config/copy';
import { API_BRIEF_ROUTES } from 'shared/config/routes/api/apiBriefRoutes';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { Brief } from 'shared/typings/briefs';
import { BrkfstUser } from 'shared/typings/user';
import { UserBriefStatus } from 'shared/typings/userBriefs/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';

interface Props {
  brief: Pick<Brief, 'id' | 'name' | 'accountId' | 'organizationId' | 'full'>;
  toggleModal: () => void;
}

const InviteCreatorsForm: React.FC<Props> = ({ brief, toggleModal }) => {
  const { id: briefId, accountId, organizationId } = brief;
  const {
    account: { enableCreditCardCharges },
  } = useAccounts({ accountId: accountId.toString() });
  const navigate = useNavigate();
  const { inviteCreators } = useBriefs();
  const [creatorEmails, setCreatorEmails] = useState<string[]>([]);

  useEffect(() => {
    // Fetch all creators that already have some relation to this brief for validation purposes
    fetchBriefCreators();
    function fetchBriefCreators() {
      axiosClient
        .httpRequest<Pick<BrkfstUser, 'id' | 'email'>[]>({
          url: LinkCreator.createApiLink({
            route: API_BRIEF_ROUTES.ALL_CREATORS,
            variables: {
              briefId,
            },
          }),
          method: 'GET',
          params: {
            accountId,
          },
        })
        .then(({ data }) => {
          setCreatorEmails(data.map(({ email }) => email));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [briefId, accountId]);

  const redirectToCheckout = ({ creatorBriefStatus, creatorEmails, creators }) => {
    navigate(
      {
        pathname: LinkCreator.createLink({
          routeKey: 'BRIEF_APPROVAL_CHECKOUT_INVITE',
          variables: {
            briefId,
            accountId,
            organizationId,
          },
        }),
      },
      {
        state: {
          creatorBriefStatus,
          creatorEmails,
          creators,
          briefName: brief.name,
        },
      },
    );
  };
  // {autoApprove: false, emails: Array(1), accountId: 3}
  const onSubmit = (formData) => {
    const { creators, [FIELDNAMES.EMAILS]: emails, ...data } = formData;
    const existingCreators = creators.map(({ value }) => value);
    const params = {
      ...data,
      emails: [...emails, ...existingCreators.map(({ email }) => email)],
      accountId,
    };
    if (data[FIELDNAMES.AUTO_APPROVE] && enableCreditCardCharges) {
      redirectToCheckout({
        creatorBriefStatus: UserBriefStatus.INVITED,
        creatorEmails: params.emails,
        creators: existingCreators,
      });
    } else {
      inviteCreators(params, briefId);
    }
    toggleModal();
  };

  const fetchCreatorOptions: FetchCreatorOptions = async (search: string, page: number) => {
    const { data } = await axiosClient.httpRequest<{ data: CreatorSelectValue[]; total: number }>({
      url: LinkCreator.createApiLink({
        route: API_BRIEF_ROUTES.UNRELATED_CREATORS,
        variables: {
          briefId,
        },
      }),
      params: {
        search,
        page,
        size: 9,
      },
      method: 'GET',
    });
    return data;
  };

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={getInviteCreatorSchema(creatorEmails)}
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {(formikProps) => (
        <Form>
          <Box css={inviteCreatorsStyles} className="invite-creators">
            <Text variant={TEXT_VARIANTS.SUBHEADING}>{CREATOR_COPY.LABEL_CREATORS}</Text>
            <Field
              name={FIELDNAMES.EXISTING_CREATORS}
              component={AsyncCreatorSelect}
              placeholder={CREATOR_COPY.LABEL_CREATORS}
              fetchCreatorOptions={fetchCreatorOptions}
              isMulti
            />
            <Field
              name={FIELDNAMES.EMAILS}
              component={TagInput}
              tagTemplate={TagEmail}
              placeholder={CREATOR_COPY.LABEL_NEW_CREATOR_EMAILS}
              backspace={false}
            />
            <ArrayErrorMessage name={FIELDNAMES.EMAILS} />
            <Field
              name={FIELDNAMES.AUTO_APPROVE}
              component={ToggleInput}
              label={CREATOR_COPY.LABEL_AUTO_APPROVE_CREATORS}
            />
            <FormButton className="invite-creators__submit">{CREATOR_COPY.BUTTON_INVITE_CREATORS}</FormButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default InviteCreatorsForm;
