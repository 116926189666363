import { css } from '@emotion/react';

import { zIndex } from 'shared/config';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export const FILTER_WIDTH = '185px';
export const FILTER_DROPDOWN_WIDTH = '255px';

export const classNames = {
  base: 'creator-search',
  search: 'creator-search__search',
  search__input: 'creator-search__search__input',
  search__icon: 'creator-search__search__input__icon',
  filter: 'creator-search__filter',
  buttonGroup: 'creator-search__button-group',
};

export default css({
  '.creator-search__search': {
    position: 'relative',
    flexDirection: 'row',
    color: COLORS.NEUTRAL600,

    '&__input': {
      width: '100%',
      margin: 0,
      '&__icon': {
        position: 'absolute',
        left: '10px',
        zIndex: zIndex.zForeground,
        top: '10px',
      },

      '& input': {
        margin: 0,
        paddingLeft: '40px',
        paddingRight: '95px',
      },
    },
    '&__vetting': {
      marginRight: theme.space[4],
    },

    '&__btn': {
      marginLeft: '0.8em',
    },
  },

  '&.creator-search__button-group': {
    '& .btn-input__option, & .btn-group__options, & .btn-input__option--selected:not(:disabled):focus, & .btn-input__option:not(:disabled):focus':
      {
        width: '100%',
      },
    '& .btn-input__option': {
      color: COLORS.NEUTRAL600,
      border: `1px solid ${COLORS.NEUTRAL600}`,
    },
    '& .btn-input__option--selected:not(:disabled), & .btn-input__option--selected:not(:disabled):focus': {
      backgroundColor: COLORS.PRIMARY50,
      color: COLORS.PRIMARY600,
      border: `1px solid ${COLORS.PRIMARY600}`,
    },
  },

  '&.creator-search__age': {
    '& .btn-group__options': {
      flexWrap: 'wrap',
    },
    '& .btn-input__option, & .btn-group__options, & .btn-input__option--selected:not(:disabled):focus, & .btn-input__option:not(:disabled):focus':
      {
        width: 'fit-content',
        minWidth: '45px',
        flexDirection: 'row',
        margin: theme.space[1],
      },
    '& .btn-input__option': {
      color: COLORS.NEUTRAL600,
      border: `1px solid ${COLORS.NEUTRAL600}`,
    },
    '& .btn-input__option--selected:not(:disabled), & .btn-input__option--selected:not(:disabled):focus': {
      backgroundColor: COLORS.PRIMARY50,
      color: COLORS.PRIMARY600,
      border: `1px solid ${COLORS.PRIMARY600}`,
    },
  },

  '.creator-search__filter': {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, minmax(${FILTER_WIDTH}, 1fr))`,
    gridTemplateRows: 'auto',
    marginTop: '1em',
    justifyItems: 'center',
    alignItems: 'start',
    gridGap: '8px',
  },
});
