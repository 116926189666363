import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '.module-scene-title': {
    '&__scene-title-wrapper': {
      alignItems: 'baseLine',
    },
    '&__scene-wrapper': {
      flexDirection: 'column',
      '.rich-text__editor': {
        padding: 0,
      },
    },
    '&__scene-error': {
      position: 'relative',
      color: COLORS.RED500,
      fontSize: theme.fontSizes[1],
      top: 0,
    },
    '&__delete-btn': {
      color: COLORS.NEUTRAL600,
    },
  },
});
