import React, { useState } from 'react';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';

import GalleryTile from 'features/media/components/molecules/GalleryTile';
import { GalleryItem, UpdateSelectedItems } from 'features/media/interfaces/gallery';
import { Box, Flex, Text } from 'shared/components/display';
import { CarouselItem } from 'shared/components/molecules/CarouselItem';
import { Lightbox } from 'shared/components/organisms/Lightbox';

import { noOp } from 'shared/defaults';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { BrkfstFile } from 'shared/typings/file';

import styles from './styles';

interface Props {
  hideAspectRatio?: boolean;
  infoModalDisabled?: boolean;
  isSelectable?: boolean;
  items: GalleryItem[];
  onShowMore?: () => void;
  renderLightbox?: boolean;
  selectedItems?: number[];
  showMoreCopy?: string;
  showPin?: boolean;
  size?: number;
  updateSelectedItems?: UpdateSelectedItems;
  useBrandName?: boolean;
  withShowMore?: boolean;
  LightboxCaption?: React.FC<any>;
  numRows?: number;
  placeholder?: string;
}
const GridGallery: React.FC<Props> = ({
  items = [],
  renderLightbox,
  infoModalDisabled,
  withShowMore,
  onShowMore,
  showMoreCopy,
  isSelectable,
  size = 250,
  selectedItems = [],
  updateSelectedItems = noOp,
  hideAspectRatio = false,
  useBrandName = false,
  showPin = false,
  LightboxCaption = undefined,
  numRows,
  placeholder = 'No items to display',
}) => {
  const [lightbox, setLightbox] = useState<{ open: boolean; index: number }>({
    open: false,
    index: 0,
  });

  const toggleSelect = (item: BrkfstFile): void => {
    if (selectedItems.includes(item.id)) {
      updateSelectedItems(selectedItems.filter((id) => id !== item.id));
    } else {
      updateSelectedItems([...selectedItems, item.id]);
    }
  };

  if (items.length < 1) {
    return (
      <Flex css={styles(size, numRows)} justifyContent="center" alignItems="center">
        <Text className="grid-gallery__placeholder" variant={TEXT_VARIANTS.BODY}>
          {placeholder}
        </Text>
      </Flex>
    );
  }

  return (
    <>
      <Box css={styles(size, numRows)} className={cs('grid-gallery')}>
        {items.map(({ itemObject, selectDisabled, selectTooltip, options, OverlayLeft }, index) => {
          const layout = {
            width: size ? size : itemObject.metadata?.width || 0,
            height: size ? size : itemObject.metadata?.height || 0,
            index,
          };
          return (
            <GalleryTile
              hideAspectRatio={hideAspectRatio}
              infoModalDisabled={infoModalDisabled}
              isSelectable={isSelectable}
              isSelected={selectedItems.includes(itemObject.id)}
              itemObject={itemObject}
              key={itemObject.thumbnailUrl}
              layout={layout}
              margin={10}
              openLightbox={renderLightbox ? (index: number) => setLightbox({ open: true, index }) : undefined}
              options={options}
              OverlayLeft={OverlayLeft}
              selectDisabled={selectDisabled}
              selectTooltip={selectTooltip}
              showPin={showPin}
              showSelectOption={!!selectedItems.length}
              src={itemObject.thumbnailUrl}
              toggleSelect={() => toggleSelect(itemObject)}
              useBrandName={useBrandName}
            />
          );
        })}
        {withShowMore && (
          <Flex className="grid-gallery__show-more" onClick={onShowMore}>
            <Flex className="grid-gallery__show-more-icon-wrapper">
              <FontAwesomeIcon icon={faArrowRight} className="grid-gallery__show-more-icon" size="xl" />
            </Flex>
            <Text className="grid-gallery__show-more-text" variant={TEXT_VARIANTS.SUBHEADING}>
              {showMoreCopy}
            </Text>
          </Flex>
        )}
      </Box>
      {renderLightbox && (
        <Lightbox
          isOpen={lightbox.open}
          selectedItemIndex={lightbox.index}
          ariaLabel="Gallery Media Preview"
          onRequestClose={() => {
            setLightbox((prev) => ({ ...prev, open: false }));
          }}
        >
          {items.map(({ itemObject, captionData }) => (
            <CarouselItem
              key={itemObject.id}
              url={itemObject.url}
              thumbnailUrl={itemObject.thumbnailUrl}
              mimeType={itemObject.mimeType}
              metadata={itemObject.metadata}
              extension={itemObject.extension}
              name={itemObject.name}
              Footer={LightboxCaption ? <LightboxCaption captionData={captionData} /> : <></>}
              useIframe={itemObject.renderInIframe}
            />
          ))}
        </Lightbox>
      )}
    </>
  );
};

export default GridGallery;
