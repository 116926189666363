import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'rebass/styled-components';
import config from 'config';

import { Box, Flex, Text } from 'shared/components/display';

import { GENERIC_COPY, GLOBAL_COPY_VARIABLES } from 'shared/config/copy/allCopy';
import brkfstLogoIcon from 'shared/images/logo/brkfst-logo-icon.svg';
import { intl } from 'shared/lib/intl';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { LinkCreator } from 'shared/utilities/linkUtility';

import styles from './styles';

const { H1, H4 } = TEXT_VARIANTS;
const { PRIMARY } = BUTTON_VARIANTS;

const ErrorPage = ({ errorType, errorMessage }) => {
  const navigate = useNavigate();

  const navigateToHomePage = (): void => {
    const path = LinkCreator.createLink({
      routeKey: 'HOME_PAGE',
    });
    navigate(path);
    window.location.assign(path);
  };

  return (
    <Flex css={styles}>
      <Text as="h1" variant={[H4, H1]} className="error-page__text">
        {errorType ? errorType : GENERIC_COPY.ERROR_PAGE_HEADER}
      </Text>
      <Box>
        <img src={brkfstLogoIcon} alt={GLOBAL_COPY_VARIABLES.APP_NAME} className="error-page__logo" />
      </Box>
      <Text as="p" variant={TEXT_VARIANTS.H6} className="error-page__text">
        {errorMessage
          ? errorMessage
          : intl.formatMessage(
              { id: 'ERROR_PAGE_BODY' },
              {
                email: (
                  <a className="error-page__email" href={`mailto:${config.GMAIL_USER}`} target="_blank">
                    {config.GMAIL_USER}
                  </a>
                ),
              },
            )}
      </Text>
      <Button variant={PRIMARY} onClick={navigateToHomePage}>
        {GENERIC_COPY.ERROR_PAGE_BUTTON}
      </Button>
    </Flex>
  );
};

export default ErrorPage;
