export const decodeHtml = (input: string): string | null => {
  // from: https://stackoverflow.com/questions/1912501/unescape-html-entities-in-javascript/34064434#34064434
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
};

export const percentScrolled = (element: HTMLDivElement): number =>
  (element.scrollTop / (element.scrollHeight - element.clientHeight)) * 100;

export const preventChildBlur = (e, callback) => {
  const currentTarget = e.currentTarget;

  requestAnimationFrame(() => {
    if (!currentTarget.contains(document.activeElement)) {
      callback();
    }
  });
};
