import React, { useMemo } from 'react';
import { Button } from 'rebass/styled-components';
import { faCircleArrowDown } from '@fortawesome/pro-duotone-svg-icons';
import { faFile, faFilterList } from '@fortawesome/pro-light-svg-icons';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import BrandApprovedCheckbox from 'features/briefs/components/atoms/BrandApprovedCheckbox';
import InviteCreators from 'features/briefs/components/organisms/InviteCreators/InviteCreators';
import Popover from 'shared/components/atoms/Popover';
import Tooltip from 'shared/components/atoms/Tooltip/NewTooltip';
import { Box, Flex, Text } from 'shared/components/display';
import { DefinitionList, ListDetail } from 'shared/components/molecules/DefinitionList';
import { PaginationFilter } from 'shared/components/molecules/PaginationFilter';
import { PaginationSort } from 'shared/components/molecules/PaginationSort';

import { useQuery } from 'shared/hooks/useQuery';
import { intl } from 'shared/lib/intl';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { Brief } from 'shared/typings/briefs';
import { BriefStatus } from 'shared/typings/briefs/enums';
import { BrkfstSearchQueryParams } from 'shared/typings/searchQueryParams';
import { UserBriefStatus } from 'shared/typings/userBriefs/enums';

import { filterOptions, ORDER_ASC, ORDER_BY, singleOptions, sortOptions, STATUS } from './config';
import styles, { briefCreatorsControlsContentStyles } from './styles';
import CopyLink from 'shared/components/atoms/CopyLink/CopyLink';
import { LinkCreator } from 'shared/utilities/linkUtility';
import { UserType } from 'shared/typings/user/enums';

interface Props {
  searchParams: BrkfstSearchQueryParams;
  brief: Brief;
  onDownloadShippingInfoClick: () => void;
  isDownloadLoading: boolean;
  creatorResultsCount: number;
  shippingRequired?: boolean;
}

const PAGE_PARAM = 'creatorsPage';

const BriefCreatorsControls: React.FC<Props> = ({
  searchParams,
  brief,
  onDownloadShippingInfoClick,
  isDownloadLoading = false,
  creatorResultsCount = 0,
  shippingRequired = false,
}) => {
  const { setQuery, parseQuery } = useQuery();

  const updateQuery = (value) => {
    const query = parseQuery(value);
    setQuery({
      ...searchParams,
      ...query,
    });
  };

  const onSortFilterChange = (option) => {
    updateQuery(option.value);
  };

  const { creatorsStatus } = searchParams;

  const showDownloadShippingButton = useMemo(
    () =>
      [UserBriefStatus.ACTIVE, UserBriefStatus.WORKING, UserBriefStatus.DRAFTED, UserBriefStatus.SUBMITTED].includes(
        creatorsStatus,
      ) &&
      creatorResultsCount > 0 &&
      shippingRequired,

    [creatorsStatus, creatorResultsCount, shippingRequired],
  );

  const showCopyLinkButton = creatorsStatus === UserBriefStatus.PENDING;

  const reviewLink = LinkCreator.createLink({
    userType: UserType.ORG,
    routeKey: 'VIEW_INDIVIDUAL_BRIEF',
    variables: {
      organizationId: brief.organizationId,
      accountId: brief.accountId,
      briefId: brief.id,
    },
    query: {
      activeTab: 2,
      status: UserBriefStatus.PENDING,
    },
  });

  const selectedFilterDisplay = filterOptions.find((option) => option.value === creatorsStatus)?.label;

  return (
    <Flex css={styles} className="brief-creators-controls">
      <Flex className="brief-creators-controls__left-wrapper">
        <Popover
          ClickComponent={
            <Button className="brief-creators-controls__display-toggle-btn" variant={BUTTON_VARIANTS.TOOL}>
              <FontAwesomeIcon icon={faFilterList} />
              {intl.formatMessage(
                { id: 'LABEL_BRIEF_CREATOR_FILTER' },
                {
                  status: selectedFilterDisplay,
                },
              )}
            </Button>
          }
          width="300px"
          offset={{
            mainAxis: 10,
            crossAxis: 0,
          }}
          contentClassName="brief-creators-controls__popover-content"
          contentStyles={briefCreatorsControlsContentStyles}
          placement="bottom-start"
          allRounded
        >
          <DefinitionList>
            <ListDetail text="Sort by">
              <PaginationSort
                onChange={onSortFilterChange}
                orderAscParamName={ORDER_ASC}
                orderByParamName={ORDER_BY}
                pageParamsName={PAGE_PARAM}
                searchParams={searchParams}
                sortOptions={sortOptions}
                singleOptions={singleOptions}
                width="250px"
                showSortIcon
              />
            </ListDetail>
            <ListDetail text={'Filter by'}>
              <PaginationFilter
                searchParams={searchParams}
                pageParamsName={PAGE_PARAM}
                filterOptions={filterOptions}
                filterParamsName={STATUS}
                onChange={onSortFilterChange}
                width="250px"
                showFilterIcon
              />
            </ListDetail>
            <ListDetail text="Additional Filters">
              <BrandApprovedCheckbox />
            </ListDetail>
          </DefinitionList>
        </Popover>
        {showCopyLinkButton && (
          <CopyLink
            link={window.location.origin + reviewLink}
            text={intl.formatMessage({ id: 'LABEL_COPY_REVIEW_LINK' })}
            dataCy="brief-creators-controls__copy-link-button"
          />
        )}
        {showDownloadShippingButton && (
          <Tooltip content="Download Shipping Information" onClick={onDownloadShippingInfoClick}>
            <Box className="fa-layers fa-fw brief-creators-controls__shipping-download-btn">
              <FontAwesomeIcon icon={faFile} size="2x" color={COLORS.NEUTRAL300} />
              <Text
                className="fa-layers-text fa-inverse brief-creators-controls__csv-icon-text"
                variant={TEXT_VARIANTS.OVERLINE}
              >
                CSV
              </Text>
              {isDownloadLoading ? (
                <FontAwesomeIcon
                  icon={faSpinner}
                  size="sm"
                  className="fa-fill brief-creators-controls__download-icon fa-spin"
                  transform={'down-14 right-13'}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faCircleArrowDown}
                  size="sm"
                  className="fa-fill brief-creators-controls__download-icon"
                  transform={'down-14 right-13'}
                />
              )}
            </Box>
          </Tooltip>
        )}
      </Flex>

      {brief.status === BriefStatus.OPEN && (
        <InviteCreators brief={brief} className="brief-creators-controls__invite-creators" />
      )}
    </Flex>
  );
};

export default BriefCreatorsControls;
