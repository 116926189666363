export default {
  CREATE_ORGANIZATION: '/org-onboarding',

  ORGANIZATION_ONBOARDING: '/org-onboarding',

  AD_HEALTH_ONBOARDING: '/ad-health-onboarding',

  AD_HEALTH_CONNECT: '/ad-health-onboarding/connect',

  EXTERNAL_AD_HEALTH: '/external-ad-health',

  AD_HEALTH_REDIRECT: '/account-health',

  TRENDS_REDIRECT: '/trends',
};
