import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

import { ModuleComment } from 'shared/typings/moduleComments';
import { BrkfstAction } from 'shared/typings/redux';
import { Revision } from 'shared/typings/revisions';
import { ModuleSubmissionStatus, ReviewModule } from 'shared/typings/submissions';

type ReviewModuleState = ReviewModule[];

const initialState = [];

type ReviewModuleAction<T = ReviewModuleState> = BrkfstAction<T>;

export const reviewModulesSlice = createSlice<ReviewModuleState, SliceCaseReducers<ReviewModuleState>>({
  name: 'reviewModules',
  initialState,
  reducers: {
    reviewModulesLoaded: (reviewModules, action: ReviewModuleAction) => {
      return action.payload.data;
    },
    reviewModuleAdded: (reviewModules, action: ReviewModuleAction<ReviewModule>) => {
      reviewModules.push(action.payload.data);
    },
    reviewModuleStatusUpdated: (reviewModules, action: ReviewModuleAction<ReviewModule>) => {
      if (action.payload.data.status === ModuleSubmissionStatus.REVIEW) {
        reviewModules.push(action.payload.data);
      } else {
        // remove module if updated status is not review
        return reviewModules.filter((reviewModule) => {
          if (reviewModule.id === action.payload.data.id) {
            return action.payload.data.status === ModuleSubmissionStatus.REVIEW;
          }
          return true;
        });
      }
    },
    revisionAdded: (
      reviewModules,
      action: PayloadAction<{
        data: Revision;
        requestData: { moduleStatus: ModuleSubmissionStatus };
      }>,
    ) => {
      const {
        data: { submissionModuleId },
        requestData: { moduleStatus },
      } = action.payload;
      return reviewModules.filter((reviewModule) => {
        if (reviewModule.id === submissionModuleId) {
          return moduleStatus === ModuleSubmissionStatus.REVIEW;
        }
        return true;
      });
    },
    moduleCommentAdded: (reviewModules, action: ReviewModuleAction<ModuleComment>) => {
      const subModule = reviewModules.find(
        (reviewModule) => reviewModule.id === action.payload.data.submissionModuleId,
      );
      if (subModule) {
        subModule.comments = [...(subModule.comments || []), action.payload.data];
      }
    },
    moduleCommentUpdated: (reviewModules, action: ReviewModuleAction<ModuleComment>) => {
      const subModule = reviewModules.find(
        (reviewModule) => reviewModule.id === action.payload.data.submissionModuleId,
      );
      if (subModule) {
        subModule.comments = subModule.comments?.map((comment) => {
          if (comment.id === action.payload.data.id) {
            return action.payload.data;
          }
          return comment;
        });
      }
    },
    moduleCommentDeleted: (reviewModules, action: ReviewModuleAction<ModuleComment>) => {
      const subModule = reviewModules.find(
        (reviewModule) => reviewModule.id === action.payload.data.submissionModuleId,
      );
      if (subModule) {
        subModule.comments = subModule.comments?.filter((comment) => comment.id !== action.payload.data.id);
      }
    },
  },
});

export const {
  moduleCommentAdded,
  moduleCommentDeleted,
  moduleCommentUpdated,
  reviewModulesLoaded,
  reviewModuleStatusUpdated,
  revisionAdded,
  reviewModuleAdded,
} = reviewModulesSlice.actions;

export const getReviewModules = (state) => state.reviewModules;
