import { ASPECT_RATIO_TOLERANCE } from 'shared/config/media';
import { BrkfstFile } from 'shared/typings/file';
import { withinPercentTolerance } from 'shared/utilities/mathUtility';

enum ASPECT_RATIO_KEYS {
  NINE_SIXTEEN = '9to16',
  FOUR_FIVE = '4to5',
  ONE_ONE = '1to1',
  SIXTEEN_NINE = '16to9',
  NO_MATCH = 'no-match',
}

const ASPECT_RATIOS = {
  [ASPECT_RATIO_KEYS.NINE_SIXTEEN]: { ratio: 9 / 16, priority: 4 },
  [ASPECT_RATIO_KEYS.FOUR_FIVE]: { ratio: 4 / 5, priority: 3 },
  [ASPECT_RATIO_KEYS.ONE_ONE]: { ratio: 1 / 1, priority: 2 },
  [ASPECT_RATIO_KEYS.SIXTEEN_NINE]: { ratio: 16 / 9, priority: 1 },
};

const checkRatio = (aspectRatio: number): { priority: number; aspectRatioKey: string } => {
  const ratioKeys = Object.keys(ASPECT_RATIOS);
  const assetRatioKey = ratioKeys.find((ratioKey) => {
    return withinPercentTolerance(aspectRatio, ASPECT_RATIOS[ratioKey].ratio, ASPECT_RATIO_TOLERANCE);
  });

  if (assetRatioKey) {
    return {
      aspectRatioKey: assetRatioKey,
      priority: ASPECT_RATIOS[assetRatioKey].priority,
    };
  }

  return { priority: 0, aspectRatioKey: ASPECT_RATIO_KEYS.NO_MATCH };
};

interface DisplayFileInfo {
  file: BrkfstFile;
  aspectRatioKey: string;
}

export const getFileToDisplay = (assets: BrkfstFile[]): DisplayFileInfo => {
  // https://dev.azure.com/agencywithin/AWPL/_wiki/wikis/Wiki/246/Submission-Review?anchor=rule%3A-prioritize-9x16-display-for-review-carousel
  const { aspectRatioKey, priority, ...file } = assets
    .map((asset): BrkfstFile & { aspectRatioKey: string; priority: number } => {
      // calculate aspect ratio and add to file
      const {
        metadata: { width = 1, height = 1 },
      } = asset;
      const aspectRatio = width / height;
      const { priority, aspectRatioKey } = checkRatio(aspectRatio);
      return { ...asset, priority, aspectRatioKey };
    })
    .reduce((displayFile, asset) => {
      if (asset.priority > displayFile.priority) {
        return asset;
      } else if (asset.priority === displayFile.priority) {
        return asset.dateCreated > displayFile.dateCreated ? asset : displayFile;
      }
      return displayFile;
    });
  return {
    file,
    aspectRatioKey,
  };
};
