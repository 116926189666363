import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export default css({
  backgroundColor: 'white',
  padding: '12px',
  borderBottom: `1px solid ${COLORS.NEUTRAL100}`,
  borderTop: `1px solid ${COLORS.NEUTRAL100}`,
  '.brief-creator-info': {
    '&__invited-creator': {
      textAlign: 'center',
      color: COLORS.NEUTRAL600,
      margin: '32px 0',
    },
    '&__header': {
      alignItems: 'start',
      justifyContent: 'space-between',
      marginBottom: '12px',
      flexDirection: 'column',
      [mediaquery.md]: {
        flexDirection: 'row',
      },
    },
    '&__name-pic-wrapper': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    '&__creator-name': {
      marginBottom: 0,
      textTransform: 'uppercase',
      textAlign: 'center',
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: 1.8,
      fontSize: '1rem',
    },
    '&__footer': {
      justifyContent: 'start',
    },
    '&__tags': {
      marginTop: theme.space[5],
    },
    '&__pic': {
      position: 'relative',
      marginBottom: theme.space[4],
    },
    '&__asset-badge': {
      color: COLORS.ORANGE500,
      position: 'absolute',
      bottom: 0,
      right: '10px',
      fontSize: '30px',
    },
    '&__elite-badge': {
      color: COLORS.ORANGE300,
      position: 'absolute',
      bottom: 0,
      left: '10px',
      fontSize: '30px',
    },
    '&__application-pitch': {
      backgroundColor: COLORS.NEUTRAL50,
      borderBottomLeftRadius: '10px',
      borderBottomRightRadius: '10px',
      padding: theme.space[3],
    },
    '&__application-pitch-label': {
      backgroundColor: COLORS.NEUTRAL50,
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      paddingLeft: theme.space[3],
    },
  },
});
