export const BRIEF_COPY = {
  // Button text
  BUTTON_PUBLISH: 'Publish Brief Now',
  BUTTON_CLOSE: 'Close Brief',
  BUTTON_REOPEN: 'Reopen Brief',
  BUTTON_CREATE_BRIEF: 'Create New Brief',
  BUTTON_DELETE_BRIEF: 'Delete Brief',
  BUTTON_DELETE_DRAFT: 'Delete Draft',
  BUTTON_VIEW_COLLECTION_BRIEF: 'Click to view full collection',
  BUTTON_APPLY_BRIEF: 'Apply',
  BUTTON_REAPPLY: 'Apply',
  BUTTON_ACCEPT_INVITE: 'Accept Invite',
  BUTTON_VIEW: 'View your submissions',
  BUTTON_BACK_TO_BRIEFS: 'View All Briefs',
  BUTTON_BACK_TO_BRIEF: 'Back to Brief',
  BUTTON_CHECKOUT_BRIEF: 'Confirm & Publish Brief',
  BUTTON_CONFIRM_CREATOR_APPROVAL: 'Confirm Creator Approval',
  BUTTON_CONNECT_IG: 'Connect Instagram to Apply',
  BUTTON_CONNECT_STRIPE: 'Connect Stripe to Submit',
  BUTTON_SAVE_BRIEF_DRAFT: 'Save Draft',
  BUTTON_SELECT_TEMPLATE: 'Select Template',
  BUTTON_SUBMIT_BRIEF: 'Start Submission',
  BUTTON_CANCEL_EDIT_BRIEF: 'Cancel',
  BUTTON_EDIT_BRIEF: 'Edit Brief',
  BUTTON_EDIT_DRAFT: 'Edit Draft',
  BUTTON_DUPLICATE: 'Duplicate Brief',
  BUTTON_DUPLICATE_DRAFT: 'Duplicate Draft',
  BUTTON_PAUSE: 'Mark as Full',
  BUTTON_RESUME: 'Resume Brief',
  BUTTON_REMOVE_SCENE: 'Delete',
  BUTTON_EDIT_SCENE: 'Edit',
  BUTTON_ADD_SCENE: 'Add Scene',
  BUTTON_COMPLETE_BRIEF_SUBMISSION: 'Finish Submission',
  BUTTON_EDIT_BRIEF_TEMPLATE: 'Publish Brief Template',
  BUTTON_MARK_FULL_PROMPT_TITLE: 'Reject all pending creators?',
  BUTTON_MARK_FULL_PROMPT_MESSAGE:
    'These creators will see they were not selected for this brief. You can always approve a rejected creator if you change your mind.',

  // Modals
  MODAL_CLOSE_UNSEEN_SUBMISSIONS:
    "There are submissions that haven't been viewed yet. \nAre you sure you want to close the brief?",
  MODAL_CLOSE:
    'Are you sure you want to close the brief? \nApproved creators will not be able to create/edit submissions once it has been closed.',
  MODAL_REOPEN:
    'Are you sure you want to reopen the brief? \nApproved creators will be able to create/edit submissions once it has been reopened.',
  MODAL_EDIT_BRIEF:
    'Please confirm that you want to edit this brief. \nEditing a brief will notify approved creators that changes have been made.',
  MODAL_DELETE_BRIEF_DRAFT: 'Are you sure you want to delete this brief draft?',
  MODAL_CONFIRM_SHIPPING_SUBHEADING:
    "We'll ship you a product if you're approved for this brief \nIs this your current shipping address?",
  MODAL_CREATOR_BRIEF_LEGAL: `In order to respond to this Brief in a contractually compliant manner and receive a Creator Fee for your Content, you must comply with each and every aspect of this Brief.\n\nBy clicking confirm:\n\n<ul><li>You acknowledge that this brief requires you to <b>shoot</b>, <b>edit content</b>, and <b>submit to all</b> modules outlined in the Brief.</li><li>You declare you have reviewed the Brief Terms and Brand Guidelines, and your Content is compliant with all terms and conditions.</li><li>You acknowledge that Creator Content that does not comply with the Brand Guidelines and/or Brief Terms may require material revisions at your own expense or may be entirely rejected by a Brand for non-compliance.</li></ul>`,
  MODAL_CREATOR_BRIEF_LEGAL_AUTO_APPROVAL: `Congratulations! You have been invited to work on this brief.\n\nBy clicking confirm:\n\n<ul><li>You are committing to work on this brief.</li><li>You acknowledge that this brief requires you to <b>shoot</b>, <b>edit content</b>, and <b>submit to all</b> modules outlined in the Brief.</li><li>You declare you have reviewed the Brief Terms and Brand Guidelines, and your Content is compliant with all terms and conditions.</li><li>You acknowledge that Creator Content that does not comply with the Brand Guidelines and/or Brief Terms may require material revisions at your own expense or may be entirely rejected by a Brand for non-compliance.</li></ul>`,
  MODAL_APPLICATION_PITCH_DESCRIPTION:
    'Please also feel free to provide a short pitch to the brand on why you are a great fit to work on this brief.',
  MODAL_INSTORE_REQUIREMENT:
    'This brief requires you to be able to travel to at least one of these states for in-store creatives.',

  // Placeholders
  PLACEHOLDER_BRIEFS: 'Get started and create a new brief today',
  PLACEHOLDER_DESCRIPTION_BRIEF: 'No description',
  PLACEHOLDER_MODULE: 'No Text',
  PLACEHOLDER_AUTO_RESPONSE: 'No auto-response',
  PLACEHOLDER_NONE_SELECTED_BRIEF: 'No Brief Selected',
  PLACEHOLDER_NO_PRODUCT: 'Not specified',
  PLACEHOLDER_NO_PAYOUT: 'Not specified',
  PLACEHOLDER_NO_BRIEFS: 'No briefs to display',

  // Labels
  LABEL_ACCOUNT: 'Account',
  LABEL_APPROVAL_PENDING: 'Approval Pending',
  LABEL_APPROVED: 'Approved',
  LABEL_AUTO_RESPONSE: 'Auto-response to Applications',
  LABEL_CLOSED: 'Closed',
  LABEL_CREATOR_PAYOUT: 'Creator Payout',
  LABEL_ELITE_CREATOR_PAYOUT: 'Elite Creator Payout',
  LABEL_DATE_UPDATED: 'Date Updated',
  LABEL_NOT_SELECTED: 'Not Selected',
  LABEL_DESCRIPTION_BRIEF: 'Description',
  LABEL_FREE_PRODUCT: 'Product',
  LABEL_MODULES: 'Modules',
  LABEL_NAME_BRIEF: 'Name',
  LABEL_PENDING: 'Your brief will be published soon',
  LABEL_PROMO_CODE: 'Promotional Code',
  LABEL_SUBMISSION_COMPLETED: 'Submission completed',
  LABEL_SUBMITTED: 'Submitted',
  LABEL_VIEWABLE_UPON_APPROVAL: 'Viewable upon brief approval',
  LABEL_WEBSITE: 'Website',
  LABEL_COPY_OF_BRIEF: 'Copy of {briefName}',
  LABEL_DEADLINE: 'Deadline',
  LABEL_FULL: 'Full',
  LABEL_FULL_DISPLAY: 'All creator spots have been filled',
  LABEL_INVITE_ONLY: 'By Invitation Only',
  LABEL_SHIPPING_REQUIRED: 'Shipping',
  LABEL_BRAND_BADGE: 'Only show creators with brand badge',
  LABEL_BRIEF_CREATOR_FILTER: '{status} Creators',
  LABEL_CREATOR_APPROVAL_PRICING: 'Creator Approval Pricing',
  LABEL_ELITE_CREATOR_APPROVAL_PRICING: 'Elite Creator Approval Pricing',

  // Links
  LINK_STRIPE_NOT_CONNECTED: 'Connect Stripe to submit',
  LINK_HOVER_STRIPE_NOT_CONNECTED: 'View in Account Settings',
  LINK_INSTAGRAM_NOT_CONNECTED: 'Connect Instagram to apply',
  LINK_HOVER_INSTAGRAM_NOT_CONNECTED: 'View in Account Settings',
  LINK_PORTFOLIO_NOT_CONNECTED: 'Add IG, website, or TikTok to apply',
  LINK_HOVER_PORTFOLIO_NOT_CONNECTED: 'View profile page',

  // Prompt
  PROMPT_SAVE_BRIEF: 'Do you want to save your brief?',
  PROMPT_SAVE_OPEN_BRIEF:
    'Please confirm that you want to edit this brief. \nEditing a brief will notify approved creators that changes have been made.',
  PROMPT_ACTIVE_BRIEF_LIMIT:
    'You have reached the maximum limit of 5 active briefs. Please finish your current briefs before applying to new ones. Thank you!',

  // Column Headers
  COLUMN_NAME_BRIEF: 'Brief',
  COLUMN_NUM_SUBMISSIONS: '# of Submissions',
  COLUMN_DATE_UPDATED: 'Date Updated',
  COLUMN_STATUS_BRIEF: 'Status',
  COLUMN_ACCOUNT: 'Brand',
  COLUMN_PAYOUT: 'Payout',

  // Warnings
  WARNING_NO_APPROVED_ASSETS: "You haven't approved any assets yet",
  WARNING_BRIEF_CLOSED: 'This brief is already closed. Please apply to another brief!',
  WARNING_FULL_BRIEF:
    "Thank you for applying, but we're unable to accept further applications, but all creator spots for this brief have been filled.\n Please refresh your page and make sure to apply to any other open briefs!",

  // headings
  HEADING_APPROVED_ASSETS: 'Approved Assets ({numAssets})',
  HEADING_APPROVE_CREATOR: 'Approve Creator',
  HEADING_BRIEF_ASSETS: 'Brief Assets',
  HEADING_BRIEF_SELECTION: 'Choose a Brief Template',
  HEADING_BRIEF_EDIT: 'Edit a Brief Template',
  HEADING_CREATE_YOUR_OWN: 'Create Your Own',
  HEADING_DETAILS: 'Details',
  HEADING_SUBMISSION_CREATION_BRIEF: 'Brief: {name}',
  HEADING_PAYMENT_SUMMARY: 'Payment Summary',

  // Subheadings
  SUBHEADING_CREATE_YOUR_OWN: 'Start with a blank brief to suit your own marketing campaign',
  SUBHEADING_SCENE: 'Scene {num} ',

  DEFAULT_BRIEF_NAME: 'Unnamed brief',

  // forms
  FORM_HEADING_INTERNAL_NOTE: 'Internal Notes',
  FORM_HEADING_DESCRIPTION: 'Description',
  FORM_TOOLTIP_DESCRIPTION:
    'Describe your intended video requirements so that the creators can produce videos for your ads. Explain the story you want to convey to your audience through the videos. Specify the key benefits of your creative campaign. Include anything and everything that will help the creators understand your brand and product better.',
  FORM_HEADING_MODULE: 'Modules',
  FORM_HEADING_FILE_TYPE: 'File Type',
  FORM_HEADING_DURATION: 'Duration (in seconds)',
  FORM_TOOLTIP_MODULE: `BRKFST allows you to break your creative brief down by segments, so that the creators can produce specific videos for each segment. You may want to specify what type of lighting is required at the beginning of the video, what location is needed as background towards the end, or whether or not you want a product shot in the middle etc. You can do this by using the modules in the brief. You can add as many modules as you like. For example, if you are a pet food company, you may want one part of the video with dogs, another with cats and a third with just the box of treats.

    Once you receive the assets, you could combine the videos from the different modules to create longer videos and compositions or use the videos from specific modules directly to create the ads.

    Please remember to specify the duration of the videos required for each module (eg. 5 seconds, 10 seconds etc).`,
  FORM_HEADING_AUTO_RESPONSE: 'Auto Response to Approved Creators',
  FORM_TOOLTIP_AUTO_RESPONSE:
    'This message will be automatically sent to any creator that applies to your brief once you approve them to work on it. Say or ask anything you want creators to know right off the bat. For example, "Please use code abc123 to get a free product from the site that you can use to shoot the videos. Limited to under $50".',
  FORM_TOOLTIP_DURATION: 'Duration limit for assets in seconds.',
  FORM_NAME_HEADING: 'Name',
  FORM_CREATOR_PAYOUT_HEADING: '{isSuperadmin, select, true {Pricing / Payouts} other{Creator Approval Pricing}}',
  FORM_PRODUCT_INSTORE: 'In Store Visit US States',
  FORM_PRODUCT_HEADING: 'Product / Service Category',
  FORM_PLACEHOLDER_NAME: 'Name of your creative brief that creators will see',
  FORM_PLACEHOLDER_DESCRIPTION: 'Describe your brand, campaign objective, target audience, etc.',
  FORM_PLACEHOLDER_FREE_PRODUCT: 'e.g., Fashion, Perfume, Home Décor, Shoes etc.',
  FORM_PLACEHOLDER_WEBSITE: 'www.website.com/product',
  FORM_PLACEHOLDER_PROMO_CODE: 'Promo code to get your product/service (visible only to creators you approve)',
  FORM_PLACEHOLDER_MODULE_DESCRIPTION:
    'Describe the scene, background and lighting, duration, CTAs, text overlays, etc.*',
  FORM_PLACEHOLDER_MODULE_TAGS: "Tags/keywords identifying the content you're looking for",
  FORM_PLACEHOLDER_AUTO_RESPONSE: 'First message automatically sent to creators when you approve them',
  FORM_PLACEHOLDER_PAYOUT: 'Creator payout in USD',
  FORM_MODULE_DISPLAY: 'Module {num}',
  FORM_PLACEHOLDER_INTERNAL_NOTE: 'This field is visible to the brkfst team only',

  TAB_TITLE_BRIEF_ASSETS: 'Brief Assets',
  TAB_TITLE_BRAND_GUIDELINES: 'Brand Guidelines',
  TAB_TITLE_BRIEF: 'Brief',
  TAB_TITLE_CREATORS: 'Creators',
  TAB_TITLE_SUBMISSIONS: 'Submissions',

  // Checkout
  CHECKOUT_HEADING: 'Publish Brief',
  CHECKOUT_SUBHEADING: 'Brief Information',
  CHECKOUT_SUCCESS_HEADING: 'Your Brief Has Been Launched!',
  CHECKOUT_SUCCESS_BODY_BRIEF_APPROVAL: 'Your payment is successful.',
  CHECKOUT_SUCCESS_BODY_BRIEF: 'Your brief will now be public for creators to see.',
  CHECKOUT_SUCCESS_BUTTON_BRIEF: 'View Brief',
  CHECKOUT_CONFIRM_LEAVE_PROCESSING_BRIEF:
    'Are you sure you want to leave this page while we are processing the Creator Approval?',

  // validation
  VALIDATION_INCOMPLETE_FIELD: 'This field is required.',
  VALIDATION_DURATION: 'Please enter a max duration greater than the specified minimum',
  VALIDATION_DURATION_INTEGER: 'Please enter a whole number of seconds',
  VALIDATION_MIN_DURATION: `Please enter a duration greater than  {minDuration} seconds`,
  VALIDATION_MAX_DURATION: `Please enter a duration less than {maxDuration} seconds`,
  VALIDATION_SCENE_ERROR: 'Please make sure all fields are filled in correctly',
  VALIDATION_NAME: "Unsupported character '}'",
  VALIDATION_STATE_REQUIREMENT: 'At least one State must be selected',

  DETAIL_PERFORMANCE_PAYOUT: '{payout} {creativeOnly, select, false {+ % ad spend} other {}}',
  CREATOR_PAYOUT_TIP:
    'In addition to the fixed payout, you will be earning a percentage of the ad spend for as long as the brand uses your assets',
};
