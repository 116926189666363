import React from 'react';
import BriefCreators from 'features/briefs/components/organisms/BriefCreators';
import BrandBriefView from 'features/briefs/components/molecules/BrandBriefView';
import { useBriefAssetsNotification } from 'features/briefs/hooks/useBriefAssetsNotification';
import SubmissionIndex from 'features/submissions/components/SubmissionIndex/SubmissionIndex';
import CenteredSpinner from 'features/ui/CenteredSpinner';
import { Box } from 'shared/components/display';
import FileListView, { FileAction } from 'shared/components/molecules/FileListView/FileListView';
import { Tab, TabBar } from 'shared/components/molecules/Tabs';
import { BRIEF_COPY } from 'shared/config/copy';
import { Brief } from 'shared/typings/briefs';
import { BriefStatus } from 'shared/typings/briefs/enums';

import brandBriefTabsStyles from './brandBriefTabsStyles';

interface Props {
  loading: boolean;
  brief: Brief;
}

const BrandBriefTabs: React.FC<Props> = ({ loading, brief }) => {
  const isPending = brief?.status === BriefStatus.PENDING;
  const isDraft = brief?.status === BriefStatus.DRAFT;

  useBriefAssetsNotification();

  if (loading || !brief) {
    return <CenteredSpinner />;
  }

  return (
    <Box css={brandBriefTabsStyles}>
      <TabBar className="brief-tabs">
        <Tab title={BRIEF_COPY.TAB_TITLE_BRIEF}>
          <BrandBriefView currentBrief={brief} loading={loading} />
        </Tab>
        <Tab title={BRIEF_COPY.TAB_TITLE_BRIEF_ASSETS} hidden={!brief.brandAssets.length}>
          <Box className="brief-tabs__assets-wrapper">
            <FileListView files={brief.brandAssets} action={FileAction.NONE} showFilePreview />
          </Box>
        </Tab>
        <Tab title={BRIEF_COPY.TAB_TITLE_CREATORS} hidden={isPending || isDraft}>
          <BriefCreators currentBrief={brief} />
        </Tab>
        <Tab
          title={BRIEF_COPY.TAB_TITLE_SUBMISSIONS}
          hidden={isPending || isDraft}
          dataCy="brand-brief-tabs__submissions"
        >
          <SubmissionIndex currentBrief={brief} />
        </Tab>
      </TabBar>
    </Box>
  );
};

export default BrandBriefTabs;
