import React, { useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Flex, Text } from 'rebass/styled-components';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field } from 'formik';
import { isEqual } from 'lodash';

import GroupInputSelectedCount from 'shared/components/atoms/GroupInputSelectedCount';
import { Box } from 'shared/components/display';
import ButtonGroupInput from 'shared/components/molecules/ButtonGroupInput/ButtonGroupInput';
import { DefinitionList, ListDetail } from 'shared/components/molecules/DefinitionList';
import { FilterForm } from 'shared/components/molecules/FilterForm';
import RangeInput from 'shared/components/molecules/RangeInput';
import { RangeTheme } from 'shared/components/organisms/RangeSlider';
import TextInput from 'shared/components/TextInput';

import axiosClient from 'shared/axiosClient';
import { API_TAG_ROUTES } from 'shared/config/routes/api/apiTagRoutes';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { OnSubmit } from 'shared/lib/formik';
import { useIntl } from 'shared/lib/intl';
import { capitalizeWithoutConjunctions } from 'shared/utilities/stringUtility';
import { formatInitialModelValues } from 'shared/utilities/tagUtility';
import NotificationBanner from 'shared/components/atoms/NotificationBanner';
import {
  ageRangeLimits,
  heightRangeLimits,
  heightValueDisplayMap,
  initialSearchBarState,
  otherOption,
  recentlyActiveOptionsDefaultActive,
  valueAccessorMap,
} from './config';
import styles, { classNames, FILTER_DROPDOWN_WIDTH, FILTER_WIDTH } from './styles';
import { FilterOption, GetAllUsersQuery, SearchBarFields, SearchBarState } from './types';
import schema from './validation';

type Props = {
  onSubmit: (searchQuery: GetAllUsersQuery) => void;
};

const defaultValue = {
  recentlyActive: recentlyActiveOptionsDefaultActive,
};

const CreatorSearch: React.FC<Props> = ({ onSubmit }) => {
  const [state, setState] = useState<{ tags: Record<string, FilterOption<string>[]>; loading: boolean }>({
    tags: {},
    loading: true,
  });
  const { formatMessage } = useIntl();
  const { isSuperadmin } = useCurrentUser();

  const initialValues: SearchBarState = useMemo(() => {
    return {
      ...initialSearchBarState,
      industry: state.tags.industry,
      niche: state.tags.niche,
      filmingBackdrop: state.tags['filming backdrop'],
      model: formatInitialModelValues(state.tags.model)?.models as FilterOption<string>[],
      ethnicity: [...(state.tags?.ethnicity || []), { ...otherOption }],
      language: [...(state.tags?.language || []), { ...otherOption }],
    };
  }, [initialSearchBarState, state]);

  const handleSubmit: OnSubmit<SearchBarState> = (state, { setSubmitting }) => {
    const searchQuery: GetAllUsersQuery = {
      recentlyActive: state.recentlyActive?.find((val) => val.selected)?.value,
      vettingStatus: state.vettingStatus?.find((val) => val.selected)?.value,
      experience: state.experience.filter((val) => val.selected).map(({ value }) => value),
      gender: state.gender.filter((val) => val.selected).map(({ value }) => value),
      search: state.search,
      //  only set if not initial values
      height: isEqual(state.height, initialValues.height)
        ? undefined
        : {
            min: state.height[0],
            max: state.height[1],
          },
      ageRange: isEqual(state.ageRange, initialValues.ageRange)
        ? undefined
        : {
            min: state.ageRange[0],
            max: state.ageRange[1],
          },
      tags: {
        industry: state.industry.filter((val) => val.selected).map(({ value }) => value),
        niche: state.niche.filter((val) => val.selected).map(({ value }) => value),
        'filming backdrop': state.filmingBackdrop.filter((val) => val.selected).map(({ value }) => value),
        model: state.model
          .filter((val) => val.selected && !['children', 'teenagers'].includes(val.value))
          .map(({ value }) => value),
        ethnicity: state.ethnicity.filter((val) => val.selected).map(({ value }) => value),
        age: state.ageTags.filter((val) => val.selected).map(({ value }) => value),
        language: state.language.filter((val) => val.selected).map(({ value }) => value),
      },
      models: {
        children: state.model.filter((val) => val.selected).some(({ value }) => value === 'children'),
        teenagers: state.model.filter((val) => val.selected).some(({ value }) => value === 'teenagers'),
      },
    };

    onSubmit(searchQuery);
    setSubmitting(false);
  };

  useEffect(() => {
    // fetch categories
    axiosClient
      .httpRequest<{ category: string; values: string[] }[]>({
        url: API_TAG_ROUTES.TAG_CATEGORIES,
        method: 'GET',
      })
      .then(({ data }) => {
        setState({
          tags: data.reduce((acc, val) => {
            acc[val.category] = val.values.map((val) => {
              return {
                value: val,
                label: capitalizeWithoutConjunctions(val),
                selected: false,
              };
            });
            return acc;
          }, {}),
          loading: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  if (state.loading) {
    return <Skeleton width="100%" height="50px" />;
  }

  return (
    <Box css={styles} className={classNames.base}>
      <FilterForm<SearchBarState>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={schema}
        validateOnBlur={false}
        valueAccessorMap={valueAccessorMap}
      >
        <Flex className={classNames.search}>
          <Text className={classNames.search__icon}>
            <FontAwesomeIcon icon={faSearch} />
          </Text>
          <Field
            className={classNames.search__input}
            name={SearchBarFields.Search}
            placeholder={formatMessage({ id: 'CREATOR_SEARCH' })}
            component={TextInput}
            dataCy={'creator-search'}
          />
        </Flex>
        <Box className={classNames.filter}>
          {isSuperadmin && (
            <FilterForm.DropdownField<Pick<SearchBarState, 'vettingStatus'>>
              filterName="Vetting Status"
              fieldNames={[SearchBarFields.VettingStatus]}
              width={FILTER_WIDTH}
              dropdownWidth={FILTER_DROPDOWN_WIDTH}
            >
              <Box className="creator-search__button-group" css={styles}>
                <ButtonGroupInput
                  name={SearchBarFields.VettingStatus}
                  className={classNames.buttonGroup}
                  buttonAlignText="left"
                />
              </Box>
            </FilterForm.DropdownField>
          )}
          <FilterForm.DropdownField<Pick<SearchBarState, 'recentlyActive'>>
            filterName="Active"
            fieldNames={[SearchBarFields.RecentlyActive]}
            width={FILTER_WIDTH}
            dropdownWidth={FILTER_DROPDOWN_WIDTH}
          >
            <Box className="creator-search__button-group" css={styles}>
              <ButtonGroupInput
                name={SearchBarFields.RecentlyActive}
                className={classNames.buttonGroup}
                buttonAlignText="left"
              />
            </Box>
          </FilterForm.DropdownField>
          <FilterForm.DropdownField<Pick<SearchBarState, 'industry'>>
            filterName="Industry"
            fieldNames={[SearchBarFields.Industry]}
            maxHeight="400px"
            width={FILTER_WIDTH}
            dropdownWidth={FILTER_DROPDOWN_WIDTH}
            LeftFooter={<GroupInputSelectedCount name={SearchBarFields.Industry} />}
          >
            <Box className="creator-search__button-group" css={styles}>
              <ButtonGroupInput
                name={SearchBarFields.Industry}
                className={classNames.buttonGroup}
                dataCy="creator-search__industry"
                buttonAlignText="left"
                isMultiSelect
              />
            </Box>
          </FilterForm.DropdownField>
          <FilterForm.DropdownField<Pick<SearchBarState, 'niche'>>
            filterName="Niche"
            fieldNames={[SearchBarFields.Niche]}
            maxHeight="400px"
            width={FILTER_WIDTH}
            dropdownWidth={FILTER_DROPDOWN_WIDTH}
            LeftFooter={<GroupInputSelectedCount name={SearchBarFields.Niche} />}
          >
            <Box className="creator-search__button-group" css={styles}>
              <ButtonGroupInput
                name={SearchBarFields.Niche}
                className={classNames.buttonGroup}
                buttonAlignText="left"
                isMultiSelect
              />
            </Box>
          </FilterForm.DropdownField>
          <FilterForm.DropdownField<Pick<SearchBarState, 'experience'>>
            filterName="Experience"
            fieldNames={[SearchBarFields.Experience]}
            width={FILTER_WIDTH}
            dropdownWidth={FILTER_DROPDOWN_WIDTH}
          >
            <Box className="creator-search__button-group" css={styles}>
              <ButtonGroupInput
                name={SearchBarFields.Experience}
                className={classNames.buttonGroup}
                buttonAlignText="left"
                isMultiSelect
              />
            </Box>
          </FilterForm.DropdownField>
          <FilterForm.DropdownField<Pick<SearchBarState, 'filmingBackdrop'>>
            filterName="Filming Backdrop"
            fieldNames={[SearchBarFields.FilmingBackdrop]}
            maxHeight="400px"
            width={FILTER_WIDTH}
            dropdownWidth={FILTER_DROPDOWN_WIDTH}
            LeftFooter={<GroupInputSelectedCount name={SearchBarFields.FilmingBackdrop} />}
          >
            <Box className="creator-search__button-group" css={styles}>
              <ButtonGroupInput
                name={SearchBarFields.FilmingBackdrop}
                className={classNames.buttonGroup}
                buttonAlignText="left"
                isMultiSelect
              />
            </Box>
          </FilterForm.DropdownField>
          <FilterForm.DropdownField<Pick<SearchBarState, 'model'>>
            filterName="Model"
            fieldNames={[SearchBarFields.Model]}
            maxHeight="400px"
            width={FILTER_WIDTH}
            dropdownWidth={FILTER_DROPDOWN_WIDTH}
            LeftFooter={<GroupInputSelectedCount name={SearchBarFields.Model} />}
          >
            <Box className="creator-search__button-group" css={styles}>
              <ButtonGroupInput
                name={SearchBarFields.Model}
                className={classNames.buttonGroup}
                dataCy="creator-search__model"
                buttonAlignText="left"
                isMultiSelect
              />
            </Box>
          </FilterForm.DropdownField>
          <FilterForm.DropdownField<Pick<SearchBarState, 'gender'>>
            filterName="Gender"
            fieldNames={[SearchBarFields.Gender]}
            maxHeight="300px"
            width={FILTER_WIDTH}
            dropdownWidth={FILTER_DROPDOWN_WIDTH}
          >
            <Box className="creator-search__button-group" css={styles}>
              <ButtonGroupInput
                name={SearchBarFields.Gender}
                className={classNames.buttonGroup}
                buttonAlignText="left"
                isMultiSelect
              />
            </Box>
          </FilterForm.DropdownField>
          <FilterForm.DropdownField<Pick<SearchBarState, 'ageTags' | 'ageRange'>>
            filterName="Age"
            fieldNames={[SearchBarFields.AgeTags, SearchBarFields.AgeRange]}
            maxHeight="400px"
            width={FILTER_WIDTH}
            dropdownWidth={FILTER_DROPDOWN_WIDTH}
          >
            <Box className="creator-search__age" css={styles}>
              <DefinitionList>
                <ListDetail text="Filter by Tags">
                  <ButtonGroupInput
                    name={SearchBarFields.AgeTags}
                    className={classNames.buttonGroup}
                    buttonAlignText="left"
                    isMultiSelect
                  />
                </ListDetail>
                <ListDetail text="Filter by Range">
                  <Field
                    component={RangeInput}
                    name={SearchBarFields.AgeRange}
                    min={ageRangeLimits.min}
                    max={ageRangeLimits.max}
                    theme={RangeTheme.MINI}
                    hideTicks
                  />
                </ListDetail>
              </DefinitionList>
              <NotificationBanner message="You can use both filters at the same time" theme="info" />
            </Box>
          </FilterForm.DropdownField>
          <FilterForm.DropdownField<Pick<SearchBarState, 'ethnicity'>>
            filterName="Ethnicity"
            fieldNames={[SearchBarFields.Ethnicity]}
            maxHeight="400px"
            width={FILTER_WIDTH}
            dropdownWidth={FILTER_DROPDOWN_WIDTH}
            LeftFooter={<GroupInputSelectedCount name={SearchBarFields.Ethnicity} />}
          >
            <Box className="creator-search__button-group" css={styles}>
              <ButtonGroupInput
                name={SearchBarFields.Ethnicity}
                className={classNames.buttonGroup}
                buttonAlignText="left"
                isMultiSelect
              />
            </Box>
          </FilterForm.DropdownField>
          <FilterForm.DropdownField<Pick<SearchBarState, 'height'>>
            filterName="Height"
            fieldNames={[SearchBarFields.Height]}
            maxHeight="300px"
            width={FILTER_WIDTH}
            dropdownWidth={FILTER_DROPDOWN_WIDTH}
          >
            <Field
              component={RangeInput}
              name={SearchBarFields.Height}
              min={heightRangeLimits.min}
              max={heightRangeLimits.max}
              theme={RangeTheme.MINI}
              valueDisplayMap={heightValueDisplayMap}
              hideTicks
            />
          </FilterForm.DropdownField>
          <FilterForm.DropdownField<Pick<SearchBarState, 'language'>>
            filterName="Language"
            fieldNames={[SearchBarFields.Language]}
            maxHeight="400px"
            width={FILTER_WIDTH}
            dropdownWidth={FILTER_DROPDOWN_WIDTH}
            LeftFooter={<GroupInputSelectedCount name={SearchBarFields.Language} />}
          >
            <Box className="creator-search__button-group" css={styles}>
              <ButtonGroupInput
                name={SearchBarFields.Language}
                className={classNames.buttonGroup}
                buttonAlignText="left"
                isMultiSelect
              />
            </Box>
          </FilterForm.DropdownField>
        </Box>
        <FilterForm.SetActiveDefaults defaultValue={defaultValue} />
      </FilterForm>
    </Box>
  );
};

export default CreatorSearch;
