import React from 'react';
import cs from 'classnames';

import TrendCopyLink from 'features/trends/components/molecules/TrendCopyLink';
import TrendEllipsisMenu from 'features/trends/components/molecules/TrendEllipsisMenu';
import Tooltip from 'shared/components/atoms/Tooltip/NewTooltip';
import { Box, Flex, Text } from 'shared/components/display';
import VideoPlayer from 'shared/components/molecules/VideoPlayer/VideoPlayer';

import { MEDIA } from 'shared/config/media';

import { trendCarouselItemStyles } from './styles';
import { CarouselItemProps } from './types';

/* If an onClick is passed to CarouselItem the control of bubbling and propagation of events is handled by the passed 
onClick event otherwise if no function is passed the default behavior of the VideoPlayer is to prevent any propagation 
of events */

type Props = Exclude<CarouselItemProps, 'Header' | 'Footer'> & {
  trendName: string;
  trendId: number;
  isSuperadmin?: boolean;
};

const TrendCarouselItem: React.FC<Props> = ({
  thumbnailUrl,
  name,
  mimeType,
  url = '',
  metadata = {},
  extension,
  showThumbnail = false,
  className,
  isSelected = false,
  trendName,
  trendId,
  onRequestClose,
  isSuperadmin,
}) => {
  const isImage = mimeType.includes(MEDIA.TYPES.IMAGE);
  const source = showThumbnail ? thumbnailUrl : url;
  const portalTarget = `trend-carousel-item-${trendId}`;

  // to prevent lightbox from closing when clicking in the carousel item
  const stopPropagation = (e) => e.stopPropagation();

  return (
    <Box css={trendCarouselItemStyles} id={portalTarget} className={cs('trend-carousel-item', className)}>
      <Flex className="trend-carousel-item__container" onClick={stopPropagation}>
        <Flex className="trend-carousel-item__header">
          <Tooltip content={trendName}>
            <Text className={cs('trend-carousel-item__header-text', 'endWithEllipsis')}>{trendName}</Text>
          </Tooltip>
          <Flex className="trend-carousel-item__header-controls">
            {isSuperadmin && (
              <TrendEllipsisMenu
                trendId={trendId}
                portalTarget={`#${portalTarget}`}
                onDelete={onRequestClose}
                showDeleteButton
                showEditButton
              />
            )}
            <TrendCopyLink trendId={trendId} portalTarget={`#${portalTarget}`} />
          </Flex>
        </Flex>
        <hr className="trend-carousel-item__hr" />
        {showThumbnail || isImage ? (
          <div className="trend-carousel-item__image-container">
            <img alt={name} src={source} title={name} className="trend-carousel-item__img" />
          </div>
        ) : (
          <VideoPlayer
            url={url}
            mimeType={mimeType}
            metadata={metadata}
            extension={extension}
            className="trend-carousel-item__video-wrapper"
            thumbnailUrl={thumbnailUrl}
            isActive={isSelected}
            stopPropagation
          />
        )}
      </Flex>
    </Box>
  );
};

export default TrendCarouselItem;
