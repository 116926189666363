import { useDispatch, useSelector } from 'react-redux';

import {
  getReviewModules,
  moduleCommentAdded,
  moduleCommentDeleted,
  moduleCommentUpdated,
  reviewModuleAdded,
  reviewModulesLoaded,
  reviewModuleStatusUpdated,
  revisionAdded,
} from 'features/reviewModules/reviewModules.slice';

import { apiAction } from 'shared/actions/api';
import { RESOURCES } from 'shared/config/resourceNames';
import { API_REVISION_ROUTES } from 'shared/config/routes/api/apiRevisionRoutes';
import { API_SUBMISSION_MODULE_ROUTES } from 'shared/config/routes/api/apiSubmissionModuleRoutes';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { ApiOnSuccess } from 'shared/typings/api';
import { ModuleComment } from 'shared/typings/moduleComments';
import { Revision } from 'shared/typings/revisions';
import { ModuleSubmissionStatus, ReviewModule } from 'shared/typings/submissions';

export const useReviewModules = () => {
  const reviewModules: ReviewModule[] = useSelector(getReviewModules);
  const dispatch = useDispatch();

  const { loading } = useComponentLoading(RESOURCES.REVIEW_MODULE, true);

  const fetchReviewModules = (briefId: number, submissionId?: number) => {
    dispatch(
      apiAction({
        path: {
          route: API_SUBMISSION_MODULE_ROUTES.REVIEW_MODULES,
          query: {
            briefId,
            submissionId,
          },
        },
        successAction: reviewModulesLoaded,
        entity: RESOURCES.REVIEW_MODULE,
      }),
    );
  };

  const updateSubmissionModuleStatus = ({
    submissionModuleId,
    status,
    onSuccess,
    onError,
  }: {
    submissionModuleId: number;
    status: ModuleSubmissionStatus;
    onSuccess?: (args) => void;
    onError?: (args) => void;
  }) => {
    dispatch(
      apiAction({
        path: {
          route: API_SUBMISSION_MODULE_ROUTES.SUBMISSION_MODULES,
          variables: {
            submissionModuleId,
          },
        },
        method: 'PATCH',
        data: {
          status,
        },
        onSuccess,
        onError,
        successAction: reviewModuleStatusUpdated,
        entity: RESOURCES.SUBMISSION_MODULE,
      }),
    );
  };

  const createModuleComment = ({
    submissionModuleId,
    moduleComment,
    onFinish,
  }: {
    submissionModuleId: number;
    moduleComment: Partial<ModuleComment>;
    onFinish?: () => void;
  }) => {
    dispatch(
      apiAction({
        path: {
          route: API_SUBMISSION_MODULE_ROUTES.MODULE_COMMENTS,
          variables: {
            submissionModuleId,
          },
        },
        data: moduleComment,
        method: 'POST',
        successAction: moduleCommentAdded,
        entity: RESOURCES.MODULE_COMMENT,
        onSuccess: onFinish,
        onError: onFinish,
      }),
    );
  };

  const updateModuleComment = (
    commentId: number,
    submissionModuleId: number,
    comment: string,
    onFinish: () => void,
  ) => {
    dispatch(
      apiAction({
        path: {
          route: API_SUBMISSION_MODULE_ROUTES.MODULE_COMMENT,
          variables: {
            submissionModuleId,
            commentId,
          },
        },
        data: {
          comment,
        },
        method: 'PATCH',
        successAction: moduleCommentUpdated,
        onSuccess: onFinish,
        onError: onFinish,
      }),
    );
  };

  const deleteModuleComment = (commentId: number, submissionModuleId: number, onFinish: () => void) => {
    dispatch(
      apiAction({
        path: {
          route: API_SUBMISSION_MODULE_ROUTES.MODULE_COMMENT,
          variables: {
            commentId,
            submissionModuleId,
          },
        },
        method: 'DELETE',
        successAction: moduleCommentDeleted,
        onSuccess: onFinish,
        onError: onFinish,
      }),
    );
  };

  const addReviewModule = (
    submissionModuleId: number,
    briefId: number,
    onSuccess?: ApiOnSuccess,
    onError?: () => void,
  ) => {
    dispatch(
      apiAction({
        path: {
          route: API_SUBMISSION_MODULE_ROUTES.REVIEW_MODULE,
          variables: {
            submissionModuleId,
          },
          query: {
            briefId,
          },
        },
        successAction: reviewModuleAdded,
        onSuccess,
        onError,
      }),
    );
  };

  const createRevision = (
    revision: Pick<Revision, 'category' | 'comment' | 'submissionModuleId'>,
    creatorId: number,
    moduleStatus: ModuleSubmissionStatus,
    onSuccess?: ApiOnSuccess,
    onError?: () => void,
  ) => {
    dispatch(
      apiAction({
        path: {
          route: API_REVISION_ROUTES.REVISIONS,
        },
        method: 'POST',
        params: {
          moduleStatus,
        },
        data: {
          ...revision,
          creatorId,
          moduleStatus,
        },
        successAction: revisionAdded,
        onSuccess,
        onError,
      }),
    );
  };

  return {
    addReviewModule,
    createModuleComment,
    createRevision,
    deleteModuleComment,
    fetchReviewModules,
    loading,
    reviewModules,
    updateModuleComment,
    updateSubmissionModuleStatus,
  };
};
