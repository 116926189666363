export const API_USER_ROUTES = Object.freeze({
  BRIEF_DEADLINE: '/user/:userId/brief-deadline',
  CHECK_EMAILS_TYPE: '/user/check-emails',
  CHECK_PORTFOLIO: '/user/:userId/check-portfolio',
  COUNTRIES: '/countries',
  COUNTRY_STATES: '/countries/:countryId/states',
  CREATOR_BRIEF_STARRED: '/user/:userId/starred',
  DELETE_USER: '/user/:userId',
  DISCONNECT_INSTAGRAM: '/user/:userId/disconnect-instagram',
  DISCONNECT_TIKTOK: '/user/:userId/disconnect-tiktok',
  ELITE_USER: '/user/:userId/elite',
  FIND_CREATORS: '/user/creators/',
  INVITE_USER: '/user/invite',
  ONBOARD_CREATOR: '/user/:userId/onboard-creator',
  ONBOARD_MARKETER: '/user/:userId/onboard-marketer',
  PHONE: '/user/:userId/phone',
  PROFILE_PICTURE: '/user/:userId/profile-pic',
  SET_USER_TYPE: '/user/:userId/set-type',
  SHIPPING_LINK: '/user/:userId/shipping-link',
  STATES: '/states',
  STRIPE_DASHBOARD: '/user/stripe-dashboard',
  UPDATE_INTERNAL_DATA: '/user/:userId/internal',
  UPDATE_ROLE: '/user/:userId/update-role',
  UPDATE_VETTING_STATUS: '/user/:userId/update-vetting-status',
  USER_ADDRESS: '/user/:userId/address',
  USER_TAGS: '/user/tags',
  USER: '/user/:userId',
  VALIDATE_PHONE: 'twilio-message/validate',
});
