// TO DO STYLES: can delete this file maybe
import { css, DefaultTheme, ThemedCssFunction } from 'styled-components/macro';

export const breakpoints = {
  sm: 360, // mobile devices
  md: 768, // tablets
  lg: 1200, // laptops
  xl: 1920, // high-res laptop or desktop
};

export const streamChatBreakpoints = {
  ...breakpoints,
  // value used by stream chat react library
  md: 960,
};

// use this to avoid retyping media queries in styled components.
// it returns an object with breakpoint names pointing to functions which will return media queries
// use by keying into appropriate breakpoint and giving css properties as argument
// ex: ${media.portraitMobile`color: antiquewhite;`}
// @ts-ignore
export const media: {
  sm: ThemedCssFunction<DefaultTheme>;
  md: ThemedCssFunction<DefaultTheme>;
  lg: ThemedCssFunction<DefaultTheme>;
  xl: ThemedCssFunction<DefaultTheme>;
} = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${breakpoints[label]}px) {
      /* istanbul ignore next */
      ${css(
        //@ts-ignore
        ...args,
      )}
    }
  `;
  return acc;
}, {});

function createQueries(breaks: typeof breakpoints) {
  const queries: any = {};
  for (const [key, value] of Object.entries(breaks)) {
    queries[key] = `@media (min-width: ${value}px)`;
  }
  return queries as Record<keyof typeof breakpoints, string>;
}

export const mediaquery = createQueries(breakpoints);
export const streamChatMediaquery = createQueries(streamChatBreakpoints);
