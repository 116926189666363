/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import cs from 'classnames';
import { Field, Form, Formik } from 'formik';
import { isEmpty } from 'lodash';

import InputGroup from 'features/accountPlatforms/components/molecules/FacebookAdInputGroup/FacebookAdInputGroup';
import AdsetSelect from 'features/accountPlatforms/facebook/AdsetSelect';
import { AdCountLedger } from 'features/accountPlatforms/facebook/Campaigns';
import {
  adCreationSchema,
  AdCreative,
  BaseAdCreativeFormData,
  carouselAdCreationSchema,
  CarouselAdCreative,
  CarouselAdCreativeFormData,
  deriveFacebookAspectRatio,
  StandardAdCreative,
  StandardAdCreativeFormData,
  styles,
  UploadPrompt,
} from 'features/accountPlatforms/facebook/components/AdCreationForm';
import LoadConfigurationBar from 'features/accountPlatforms/facebook/LoadConfigurationBar';
import { useConfigurations } from 'features/accountPlatforms/facebook/useConfigurations';
import { useAccountPlatforms } from 'features/accountPlatforms/useAccountPlatforms';
import CenteredSpinner from 'features/ui/CenteredSpinner';
import TrackDirtyState from 'shared/components/atoms/TrackDirtyState';
import { Box, Flex, Image, Text } from 'shared/components/display';
import FormButton from 'shared/components/FormButton';
import FormikErrorFocus from 'shared/components/forms/FormikErrorFocus';
import SaveConfigurationForm from 'shared/components/forms/SaveConfigurationForm';
import Heading from 'shared/components/molecules/Heading';
import Modal from 'shared/components/molecules/Modal';
import ParameterizedTextInput from 'shared/components/molecules/ParameterizedTextInput';
import { SelectInput } from 'shared/components/molecules/Select';
import TextareaInput from 'shared/components/TextareaInput/TextareaInput';
import TextInput from 'shared/components/TextInput';
import ToggleInput from 'shared/components/ToggleInput';

import { apiAction } from 'shared/actions/api';
import {
  AD_NAME,
  ADD_END_CARD,
  ADS,
  BODY,
  CALL_TO_ACTION,
  DESCRIPTION,
  DISPLAY_URL,
  HEADLINE,
  LANDING_PAGE,
  OPTIMIZE,
  SEE_MORE_URL,
  URL_TAGS,
} from 'shared/config/ads';
import { ACCOUNT_PLATFORM_COPY, FACEBOOK_COPY } from 'shared/config/copy';
import { RESOURCES } from 'shared/config/resourceNames';
import { API_FACEBOOK_ROUTES } from 'shared/config/routes/api/apiFacebookRoutes';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { useFormData } from 'shared/hooks/useFormData';
import { makeArray } from 'shared/lib/formik';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { CLASSNAMES } from 'shared/styles/containers';
import { breakpoints } from 'shared/styles/styleFunctions';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { Type } from 'shared/typings/facebookBusinessAsset/enums';
import { BrkfstFile } from 'shared/typings/file';
import { LinkCreator } from 'shared/utilities/linkUtility';
import { parseDynamicString } from 'shared/utilities/stringUtility';

import {
  AD_CREATION_INITIAL_VALUES,
  ASSET_INITIAL_VALUES,
  CALL_TO_ACTION_OPTIONS,
  CAROUSEL_AD_DYNAMIC_VARIABLES,
  CAROUSEL_INITIAL_VALUES,
  CREATOR_DYNAMIC_VARIABLES,
  FACEBOOK_URL_DYNAMIC_VARIABLES,
  STANDARD_AD_DYNAMIC_VARIABLES,
} from './config';
import { ProductSetInput } from './productSet';
import { parseFacebookDynamicUrlString } from './utils';
import { collectionAdCreationSchema } from './validation';

const { FACEBOOK } = ADS;
const { H3 } = TEXT_VARIANTS;
const AD_COUNT_LIMIT = 50;

const BODY_LABEL = 'Primary Text';
const CALL_TO_ACTION_LABEL = 'Call to Action';
const DISPLAY_URL_LABEL = 'Display Url';
const SEE_MORE_URL_LABEL = 'See More Url';
const ADD_END_CARD_LABEL = 'Add End Card';
const OPTIMIZE_LABEL = 'Show Best Performing Card First';
const AD_NAME_LABEL = 'Ad Name';
const URL_TAGS_LABEL = 'Url Tags';
const LANDING_PAGE_LABEL = 'Landing Page';
const HEADLINE_LABEL = 'Headline';
const DESCRIPTION_LABEL = 'Description';
const CREATIVE_NAME_LABEL = 'Creative Name';
const ADSETS_LABEL = 'Adsets';

const FACEBOOK_AD_NAME_LIMIT = 400;

interface State {
  ads: AdCreative[];
  adCountErrors: AdCountLedger[];
  formattedAssets: BrkfstFile[];
  isUploadOpen: boolean;
  isSaveConfigOpen: boolean;
}

/**
 * This component renders a form to upload ads after an initial asset upload
 */
const FbAdCreation = () => {
  const navigate = useNavigate();
  const location = useLocation<any>();
  const params = useParams<any>();
  const dispatch = useDispatch();

  const { organizationId, accountId, accountPlatformId } = params;
  const { loading } = useComponentLoading(RESOURCES.FACEBOOK, false);
  const { loading: adsetLoading } = useComponentLoading('Adset');
  const { selectedAssets, type, moduleNum } = useMemo(() => location?.state || {}, [location]);

  const { accountPlatform } = useAccountPlatforms({
    accountPlatformId: +accountPlatformId,
  });
  const productCatalog = useMemo(
    () => accountPlatform.facebookBusinessAssets?.find((asset) => asset.type === Type.PRODUCT_CATALOG),
    [accountPlatform.facebookBusinessAssets],
  );

  const { fetchConfigurations, defaultConfiguration } = useConfigurations();

  const { savedData } = useFormData();

  const isCarousel = type === FACEBOOK.TYPES.CAROUSEL;
  const isCollection = type === FACEBOOK.TYPES.COLLECTION;
  const defaultConfig = defaultConfiguration.value;
  const isSystemAccount = !accountPlatform.type;
  const carouselAssets = defaultConfig.carouselAssets;

  const [state, setState] = useState<State>({
    ads: [],
    adCountErrors: [],
    formattedAssets: [],
    isUploadOpen: false,
    isSaveConfigOpen: false,
  });
  const [touched, setTouched] = useState(false);

  const resetState = () => {
    setState((prev) => ({
      ...prev,
      ads: [],
      adCountErrors: [],
      isUploadOpen: false,
      isSaveConfigOpen: false,
    }));
  };
  const { ads, adCountErrors, formattedAssets, isUploadOpen, isSaveConfigOpen } = state;

  const pathToAssetLibrary = useMemo(
    () =>
      LinkCreator.createLink({
        routeKey: 'ASSETS_LIBRARY',
        variables: {
          accountId,
          organizationId,
          accountPlatformId,
        },
      }),
    [accountId, accountPlatformId, organizationId],
  );

  const isMobile = useMediaQuery({
    maxWidth: breakpoints.md - 1,
  });

  /**
   * Based off previous-code isolated redirect use-effect.
   */
  useEffect(() => {
    if (!isSystemAccount || !type || isEmpty(selectedAssets)) {
      navigate(pathToAssetLibrary);
    }
  }, [selectedAssets, type, isSystemAccount, pathToAssetLibrary]);

  useEffect(() => {
    fetchConfigurations({
      entityType: 'account_platforms',
      entityId: accountPlatformId,
    });
  }, [accountPlatformId, type]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      formattedAssets: selectedAssets,
    }));
  }, [selectedAssets]);

  const openConfigModal = () => {
    setState((prev) => ({ ...prev, isSaveConfigOpen: true }));
  };

  const closeConfigModal = () => {
    setState((prev) => ({ ...prev, isSaveConfigOpen: false }));
  };

  const redirectToCheckout = (formData: BaseAdCreativeFormData) => {
    const pathToCheckout = LinkCreator.createLink({
      routeKey: 'FB_AD_LAUNCH',
      variables: {
        accountId,
        organizationId,
        accountPlatformId,
      },
    });
    navigate(
      {
        pathname: pathToCheckout,
      },
      {
        state: {
          ...location.state,
          ads,
          formData,
          formattedAssets,
          moduleNum,
          adsetIds: formData.adsets.map(({ value }) => value),
          productSetId: formData.productSet?.value,
        },
      },
    );
    resetState();
  };

  const hasSubmittedAssets = useMemo(() => formattedAssets.some((asset) => asset.submitted), [formattedAssets]);

  const createStandardAdCreative = (formData: StandardAdCreativeFormData): StandardAdCreative => {
    const dynamicStringMap: Record<string, any> = { ...formData };
    if (hasSubmittedAssets) {
      const lastNameFirstName = `${formattedAssets[0].creatorLastName}${formattedAssets[0].creatorFirstName}`;
      dynamicStringMap.creatorName = lastNameFirstName.replace(/\s/g, '');
      dynamicStringMap.moduleNumber = moduleNum;
    }
    return {
      ...formData,
      callToAction: formData.callToAction.value,
      adName: parseDynamicString(formData.adName, dynamicStringMap),
      urlTags: parseFacebookDynamicUrlString(formData.urlTags, dynamicStringMap),
      assets: formattedAssets.map((asset) => ({
        fileId: asset.id,
        fileUrl: asset.url,
        fileName: asset.name,
        thumbnailUrl: asset.thumbnailUrl,
        labels: asset.tagNames,
        aspectRatio: deriveFacebookAspectRatio(asset.metadata.width, asset.metadata.height),
      })),
    };
  };

  const createCarouselAdCreative = (formData: CarouselAdCreativeFormData): CarouselAdCreative => {
    const dynamicStringMap: Record<string, any> = { ...formData };
    if (hasSubmittedAssets) {
      const lastNameFirstName = `${formData.carouselAssets[0].asset.creatorLastName}${formData.carouselAssets[0].asset.creatorFirstName}`;
      dynamicStringMap.creatorName = lastNameFirstName.replace(/\s/g, '');
      dynamicStringMap.moduleNumber = moduleNum;
    }
    return {
      ...formData,
      callToAction: formData.callToAction.value,
      adName: parseDynamicString(formData.adName, dynamicStringMap),
      urlTags: parseFacebookDynamicUrlString(formData.urlTags, dynamicStringMap),
      carouselAssets: formData.carouselAssets.map(({ asset, ...copy }) => ({
        ...copy,
        fileId: asset.id,
        fileName: asset.name,
        fileUrl: asset.url,
        labels: asset.tagNames,
        thumbnailUrl: asset.thumbnailUrl,
      })),
    };
  };

  const promptOnClick = (formData: any) => {
    const ad = isCarousel ? createCarouselAdCreative(formData) : createStandardAdCreative(formData);
    dispatch(
      apiAction({
        path: {
          route: API_FACEBOOK_ROUTES.ADSET_AD_COUNT,
        },
        params: {
          adsetIds: formData.adsets.map(({ value }) => value),
          accountPlatformId,
        },
        entity: 'Adset',
        onSuccess: ({ data: adCounts }) => {
          setState((prev) => ({
            ...prev,
            ads: [ad],
            // Maintaining array syntax. Backend services are set-up to process more than one Ad if ever the case.
            adCountErrors: adCounts.filter(({ total }) => total + 1 > AD_COUNT_LIMIT),
          }));
        },
      }),
    );
    setState((prev) => ({
      ...prev,
      isUploadOpen: true,
    }));
  };

  const creatorDynamicVariables = useMemo(
    () => (hasSubmittedAssets ? CREATOR_DYNAMIC_VARIABLES : []),
    [hasSubmittedAssets],
  );

  const adNameDynamicVariables = useMemo(() => {
    if (isCarousel) return creatorDynamicVariables.concat(CAROUSEL_AD_DYNAMIC_VARIABLES);
    return creatorDynamicVariables.concat(STANDARD_AD_DYNAMIC_VARIABLES);
  }, [isCarousel, creatorDynamicVariables]);

  const urlDynamicVariables = useMemo(() => {
    if (isCarousel)
      return creatorDynamicVariables.concat(CAROUSEL_AD_DYNAMIC_VARIABLES).concat(FACEBOOK_URL_DYNAMIC_VARIABLES);
    return creatorDynamicVariables.concat(STANDARD_AD_DYNAMIC_VARIABLES).concat(FACEBOOK_URL_DYNAMIC_VARIABLES);
  }, [isCarousel, creatorDynamicVariables]);

  const initialValues = useMemo(() => {
    const defaultValues = {
      adsets: [], // Required for adset multi-select, at-top incase overridden below
      productSet: null,
      ...(isCarousel ? CAROUSEL_INITIAL_VALUES : AD_CREATION_INITIAL_VALUES),
      ...savedData,
      ...defaultConfig,
    };
    if (isCarousel) {
      if (defaultConfig.carouselAssets) {
        defaultValues.carouselAssets = carouselAssets.map((carAsset, i) => {
          return { ...carAsset, asset: formattedAssets[i] };
        });
      } else {
        defaultValues.carouselAssets = formattedAssets.map((asset) => ({
          asset,
          ...ASSET_INITIAL_VALUES,
        }));
      }
    } else {
      defaultValues.assets = formattedAssets;
    }
    return defaultValues;
  }, [isCarousel, savedData, defaultConfig, carouselAssets, formattedAssets]);

  const validationSchema = useMemo(() => {
    if (isCarousel) return carouselAdCreationSchema;
    else if (isCollection) return collectionAdCreationSchema;
    return adCreationSchema;
  }, [isCarousel, isCollection]);

  return (
    <Flex css={styles} className={CLASSNAMES.MAIN_CONTAINER}>
      <CenteredSpinner visible={loading} className={cs({ 'ad-creation-form--hidden': !loading })} />
      <div className={cs({ 'ad-creation-form--hidden': loading })}>
        <Text variant={H3} className="ad-creation-form__heading">
          {FACEBOOK_COPY.HEADING_FORM}
        </Text>
        <LoadConfigurationBar isMobile={isMobile} />
        <Formik
          enableReinitialize
          validateOnBlur={false}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            promptOnClick(values);
            actions.setSubmitting(false);
          }}
        >
          {(formikProps) => (
            <Form>
              <TrackDirtyState setDirtyState={setTouched} />
              <FormikErrorFocus offset={-30} align="top" ease="linear" duration={200} formik={formikProps} />
              <AdsetSelect name="adsets" placeholder={ADSETS_LABEL} accountPlatformId={accountPlatformId} />
              {isCollection && <ProductSetInput productCatalog={productCatalog} />}
              {!isCarousel && <StandardInputGroup />}
              {isCarousel && (
                <>
                  <CarouselInputGroup name="carouselAssets" />
                  <InputGroup
                    name={BODY}
                    label={BODY_LABEL}
                    component={TextareaInput}
                    softMaxLength={72}
                    maxLength={255}
                  />
                </>
              )}
              <Text className="ad-creation-form__call-to-action-label" variant={TEXT_VARIANTS.H5}>
                Call to Action
              </Text>
              <Field
                name={CALL_TO_ACTION}
                component={SelectInput}
                options={CALL_TO_ACTION_OPTIONS}
                placeholder={CALL_TO_ACTION_LABEL}
                width={isMobile ? '100%' : '400px'}
              />
              <Field
                name={DISPLAY_URL}
                component={TextInput}
                label={DISPLAY_URL_LABEL}
                placeholder={DISPLAY_URL_LABEL}
              />
              {isCarousel && (
                <>
                  <Field
                    name={SEE_MORE_URL}
                    component={TextInput}
                    label={SEE_MORE_URL_LABEL}
                    placeholder={SEE_MORE_URL_LABEL}
                  />
                  <Field name={ADD_END_CARD} component={ToggleInput} label={ADD_END_CARD_LABEL} />
                  <Field name={OPTIMIZE} component={ToggleInput} label={OPTIMIZE_LABEL} />
                </>
              )}
              <Heading
                text={FACEBOOK_COPY.HEADING_FORM_DYNAMIC_VARIABLES}
                variant={TEXT_VARIANTS.H5}
                descriptionText={FACEBOOK_COPY.FORM_DESCRIPTION_DYNAMIC_VARIABLES}
              />
              <ParameterizedTextInput
                name={AD_NAME}
                maxLength={FACEBOOK_AD_NAME_LIMIT}
                variables={adNameDynamicVariables}
                label={AD_NAME_LABEL}
                placeholder={AD_NAME_LABEL}
              />
              <ParameterizedTextInput
                name={URL_TAGS}
                variables={urlDynamicVariables}
                label={URL_TAGS_LABEL}
                placeholder={URL_TAGS_LABEL}
              />
              <Box mt={4} className="ad-creation-form__form-buttons">
                <FormButton>Upload</FormButton>
                <FormButton
                  type="button"
                  onClick={openConfigModal}
                  variant={BUTTON_VARIANTS.OUTLINE}
                  disabled={!touched}
                >
                  Save Configuration
                </FormButton>
              </Box>
              <Modal
                isOpen={isUploadOpen}
                title={FACEBOOK_COPY.PROMPT_AD_UPLOAD_TITLE}
                component={
                  <UploadPrompt
                    ads={ads}
                    loading={adsetLoading}
                    adCountErrors={adCountErrors}
                    onClick={() => {
                      redirectToCheckout(formikProps.values);
                    }}
                  />
                }
                onRequestClose={resetState}
              />
              <Modal
                isOpen={isSaveConfigOpen}
                title={ACCOUNT_PLATFORM_COPY.FORM_HEADING_SAVE_CONFIG}
                component={
                  <SaveConfigurationForm
                    type={`ad-creation-${type}`}
                    formData={formikProps.values}
                    entityType="account_platforms"
                    entityId={accountPlatformId}
                    onRequestClose={closeConfigModal}
                  />
                }
                onRequestClose={closeConfigModal}
              />
            </Form>
          )}
        </Formik>
      </div>
    </Flex>
  );
};

const CarouselInputGroup = makeArray<{ asset: BrkfstFile }>(({ name, values }) => (
  <Box>
    {values.map(
      (val, ndx) =>
        val.asset && (
          <Fragment key={val.asset.id}>
            <AssetInput asset={val.asset} name={`${name}.${ndx}.asset`} />
            <InputGroup name={`${name}.${ndx}.${LANDING_PAGE}`} label={LANDING_PAGE_LABEL} maxLength={125} />
            <InputGroup
              name={`${name}.${ndx}.${HEADLINE}`}
              label={HEADLINE_LABEL}
              softMaxLength={27}
              maxLength={255}
              labelMaxLength={255}
            />
            <InputGroup
              name={`${name}.${ndx}.${DESCRIPTION}`}
              label={DESCRIPTION_LABEL}
              softMaxLength={27}
              maxLength={10000}
            />
          </Fragment>
        ),
    )}
  </Box>
));

const StandardInputGroup: React.FC = () => (
  <Box>
    <Flex>
      <AssetInputs name="assets" />
    </Flex>
    <InputGroup name={LANDING_PAGE} label={LANDING_PAGE_LABEL} />
    <InputGroup name={HEADLINE} label={HEADLINE_LABEL} softMaxLength={27} maxLength={255} labelMaxLength={255} />
    <InputGroup name={BODY} label={BODY_LABEL} component={TextareaInput} softMaxLength={72} />
    <InputGroup
      name={DESCRIPTION}
      label={DESCRIPTION_LABEL}
      softMaxLength={27}
      maxLength={10000}
      labelMaxLength={255}
    />
  </Box>
);

const AssetInputs = makeArray<BrkfstFile>(({ name, values }) => (
  <>
    {values.map((asset, ndx) => (
      <AssetInput key={asset.id} asset={asset} name={`${name}.${ndx}`} />
    ))}
  </>
));

const AssetInput: React.FC<{ asset: BrkfstFile; name: string }> = ({ asset, name }) => (
  <Flex flexDirection="column" width={[1 / 2, 1, 1 / 6]} pr={2} mt={4}>
    <Image src={asset.thumbnailUrl} alt="Asset Preview" />
    <Field name={`${name}.name`} component={TextInput} placeholder={asset.name} label={CREATIVE_NAME_LABEL} disabled />
  </Flex>
);

export default FbAdCreation;
