import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  '.invite-creators': {
    '&__submit': {
      margin: `${theme.space[4]} 0`,
    },
  },
});
