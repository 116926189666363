import React, { useState } from 'react';
import { Button } from 'rebass/styled-components';
import { Field, useFormikContext } from 'formik';
import shortid from 'shortid';

import { FormBrief, ModuleSceneValues } from 'features/briefs/components/BriefForm/types';
import { MAX_SCENES, MIN_SCENES, SCENE_FIELD_NAMES } from 'features/briefs/components/BriefForm/utils';
import { Box, Flex, Text } from 'shared/components/display';
import { RICHTEXT_DEFAULT_VALUE, RichtextInput } from 'shared/components/Richtext';
import TextInput from 'shared/components/TextInput';

import { BRIEF_COPY } from 'shared/config/copy';
import { makeArray, makeLabel } from 'shared/lib/formik';
import { intl } from 'shared/lib/intl';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { preventChildBlur } from 'shared/utilities/domUtility';

import ModuleSceneTitle from '../ModuleSceneTitle/ModuleSceneTitle';

import styles from './styles';

const moduleSceneDefaults: ModuleSceneValues = {
  title: '',
  description: RICHTEXT_DEFAULT_VALUE,
};
export const createScene = () => ({ ...moduleSceneDefaults, formId: shortid.generate() });

interface Props {
  moduleIndex: number;
}

const ModuleScenesForm = makeArray<ModuleSceneValues, Props>(({ remove, push, values, name, moduleIndex }) => {
  const [openScene, setOpenScene] = useState<number>(-1);
  const { values: formikValues, errors, touched, setFieldTouched } = useFormikContext<FormBrief>();
  const [relatedTarget, setRelatedTarget] = useState(null);
  const onEditScene = (idx) => {
    setOpenScene(idx);
  };

  const onSceneBlur = (e, index) => {
    // Prevent default blur event as it will close all the scenes instead of opening the next one, then handle it manually
    // by requesting and handling the collapse animation manually before the expand animation is painted
    // Add a case for the link tootltip form so scene doesn't close when clicked
    const notExcludedInternalElements =
      !e?.relatedTarget?.textContent.includes(BRIEF_COPY.BUTTON_EDIT_SCENE) &&
      // @ts-ignore
      !relatedTarget?.textContent.includes('Apply') &&
      !e?.relatedTarget?.className.includes('rich');
    const includedExternalElement = e?.relatedTarget?.textContent.includes(BRIEF_COPY.BUTTON_ADD_SCENE);
    if ((notExcludedInternalElements && e?.relatedTarget === null) || includedExternalElement) {
      // Revert initial touched state caused by the autofocus
      setFieldTouched(`${name}[${index}].${SCENE_FIELD_NAMES.TITLE}`, false);
      preventChildBlur(e, () => setOpenScene(-1));
    }
    setRelatedTarget(e?.relatedTarget);
  };

  const onRemove = (idx) => {
    remove(idx);
    setTimeout(() => setOpenScene(values.length - 2), 350);
  };

  const onPush = () => {
    push(createScene());
    setOpenScene(values.length);
  };

  const renderSceneForm = (scene, idx) => (
    <Box>
      <Flex className="module-scenes-form__header">
        <Text className="module-scenes-form__label" variant={TEXT_VARIANTS.SUBHEADING}>
          {intl.formatMessage({ id: 'SUBHEADING_SCENE' }, { num: idx + 1 })}
        </Text>
        <Button
          type="button"
          variant={BUTTON_VARIANTS.TEXT_ONLY}
          className="module-scenes-form__delete-btn"
          disabled={values.length <= MIN_SCENES}
          onClick={() => onRemove(idx)}
        >
          {BRIEF_COPY.BUTTON_REMOVE_SCENE}
        </Button>
      </Flex>
      <Box>
        <Field
          name={`${name}[${idx}].${SCENE_FIELD_NAMES.TITLE}`}
          component={TextInput}
          placeholder={makeLabel(SCENE_FIELD_NAMES.TITLE, true)}
          label={makeLabel(SCENE_FIELD_NAMES.TITLE, true)}
          className="module-scenes-form__input"
        />
        <Field
          name={`${name}[${idx}].${SCENE_FIELD_NAMES.DESCRIPTION}`}
          key={scene.id || scene.formId}
          component={RichtextInput}
          placeholder={makeLabel(SCENE_FIELD_NAMES.DESCRIPTION, true)}
          label={makeLabel(SCENE_FIELD_NAMES.DESCRIPTION, true)}
          readableWidth
          tabIndex={0}
        />
      </Box>
    </Box>
  );

  return (
    <Box css={styles} className="module-scenes-form">
      {values?.map((scene, idx) => (
        <Box
          key={`${moduleIndex}-${scene.title}-${idx}`}
          onBlur={(e) => onSceneBlur(e, idx)}
          className="module-scenes-form__scene-wrapper"
        >
          {openScene === idx ? (
            renderSceneForm(scene, idx)
          ) : (
            <ModuleSceneTitle
              idx={idx}
              formikValues={formikValues}
              values={values}
              moduleIndex={moduleIndex}
              onEditScene={onEditScene}
              handleRemove={() => onRemove(idx)}
              errors={errors}
              touched={touched}
            />
          )}
        </Box>
      ))}
      <Button
        type="button"
        className="module-scenes-form__add-btn"
        disabled={values?.length >= MAX_SCENES}
        onClick={() => onPush()}
      >
        {BRIEF_COPY.BUTTON_ADD_SCENE}
      </Button>
    </Box>
  );
});

export default ModuleScenesForm;
