import React, { useMemo } from 'react';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { differenceInMonths } from 'date-fns';
import { Field, useFormikContext } from 'formik';
import * as yup from 'yup';

import { Box, Text } from 'shared/components/display';
import { StepFieldProps } from 'shared/components/forms/MultiStepForm/interfaces';
import ButtonGroupInput, { Option } from 'shared/components/molecules/ButtonGroupInput/ButtonGroupInput';
import { buttonGroupSchema } from 'shared/components/molecules/ButtonGroupInput/validation';
import Counter from 'shared/components/molecules/Counter';
import DateInput from 'shared/components/organisms/DateInput';

import { CREATOR_COPY } from 'shared/config/copy';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { CHILD_TAGS, TEENAGER_TAGS } from 'shared/utilities/tagUtility';

import styles from './styles';

export const formatDependentDOB = (dob: string) => {
  const [month, year] = dob.split('/');
  const currentDayOfMonth = new Date().getDate();
  const day = currentDayOfMonth > 28 ? 28 : currentDayOfMonth; // set DOB to current day for highest possible accuracy but prevent potential issues by normalisizing to 28th
  return `${month}/${day}/${year}`;
};

export const ModelAvailabilityFormFields: React.FC<StepFieldProps> = ({ className }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<ModelValues>();

  const onChildrenChange = (count: number) => {
    const currentChildren = values?.children;
    const difference = count - (currentChildren?.length || 0);
    const newValue =
      difference > 0
        ? (currentChildren || []).concat(Array.from({ length: difference }, () => ({ dob: '' })))
        : currentChildren?.slice(0, count);
    setFieldValue(MODEL_AVAILABILITY_FIELDNAMES.CHILDREN, newValue);
    setFieldTouched(MODEL_AVAILABILITY_FIELDNAMES.CHILDREN, true);
  };

  const onTeenagersChange = (count: number) => {
    const currentTeenagers = values?.teenagers;
    const difference = count - (currentTeenagers?.length || 0);
    const newValue =
      difference > 0
        ? (currentTeenagers || []).concat(Array.from({ length: difference }, () => ({ dob: '' })))
        : currentTeenagers?.slice(0, count);
    setFieldValue(MODEL_AVAILABILITY_FIELDNAMES.TEENAGERS, newValue);
    setFieldTouched(MODEL_AVAILABILITY_FIELDNAMES.TEENAGERS, true);
  };

  const showChildren = useMemo(
    () => values?.models.filter(({ selected, value }) => selected && CHILD_TAGS.includes(value)).length > 0,
    [values.models],
  );

  const showTeenagers = useMemo(
    () => values?.models.filter(({ selected, value }) => selected && TEENAGER_TAGS.includes(value)).length > 0,
    [values.models],
  );

  return (
    <Box css={styles}>
      <Text variant={TEXT_VARIANTS.BODY} className={className}>
        {CREATOR_COPY.CAPTION_MULTIPLE_OPTIONS}
      </Text>
      <ButtonGroupInput name={MODEL_AVAILABILITY_FIELDNAMES.MODELS} isMultiSelect />
      {showChildren && (
        <>
          <Text variant={TEXT_VARIANTS.BODY} className="models__subheading">
            How many children are available to participate in your videos?
          </Text>
          <Counter
            onChange={onChildrenChange}
            initialCount={values[MODEL_AVAILABILITY_FIELDNAMES.CHILDREN]?.length}
            max={10}
          />
          <Text variant={TEXT_VARIANTS.BODY} className="models__subheading">
            Age of Children
          </Text>
          {values?.children?.map((child, index) => (
            <Field
              component={DateInput}
              name={`${MODEL_AVAILABILITY_FIELDNAMES.CHILDREN}.[${index}].dob`}
              label="Date of birth"
              key={index}
              showDayPicker={false}
              mask="99/9999"
              placeholder="MM/YYYY"
            />
          ))}
        </>
      )}
      {showTeenagers && (
        <>
          <Text variant={TEXT_VARIANTS.BODY} className="models__subheading">
            How many teenagers are available to participate in your videos?
          </Text>
          <Counter
            onChange={onTeenagersChange}
            initialCount={values[MODEL_AVAILABILITY_FIELDNAMES.TEENAGERS]?.length}
            max={10}
          />
          <Text variant={TEXT_VARIANTS.BODY} className="models__subheading">
            Age of Teenagers
          </Text>
          {values?.teenagers?.map((child, index) => (
            <Field
              component={DateInput}
              name={`${MODEL_AVAILABILITY_FIELDNAMES.TEENAGERS}.[${index}].dob`}
              label="Date of birth"
              key={index}
              showDayPicker={false}
              mask="99/9999"
              placeholder="MM/YYYY"
            />
          ))}
        </>
      )}

      {(showChildren || showTeenagers) && (
        <Box className="models__footer">
          <Box className="models__note">
            <FontAwesomeIcon
              icon={faCircleExclamation}
              size="lg"
              color={COLORS.ORANGE500}
              className="models__note-icon"
            />
            <Text variant={TEXT_VARIANTS.SUBHEADING_SM} className="models__note-text">
              {CREATOR_COPY.CAPTION_MODELS_NOTE}
            </Text>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export const MODEL_AVAILABILITY_FIELDNAMES = {
  MODELS: 'models',
  CHILDREN: 'children',
  TEENAGERS: 'teenagers',
};

export const modelSchema = yup.object({
  models: buttonGroupSchema,
  children: yup
    .array()
    .of(
      yup.object({
        dob: yup
          .string()
          .required('Date of birth is required')
          .matches(/^(0[1-9]|1[0-2])\/\d{4}$/, 'Date of birth must be in MM/YYYY format')
          .test({
            name: 'child-age-range',
            test: (dob) => {
              if (!dob) return false;
              dob = formatDependentDOB(dob);
              return (
                differenceInMonths(new Date(), new Date(dob)) >= 0 &&
                differenceInMonths(new Date(), new Date(dob)) < 13 * 12
              ); // dob isn't future date and is under 13 years
            },
            message: 'Children must be less than 13 years old',
          }),
      }),
    )
    .optional()
    .max(10),
  teenagers: yup
    .array()
    .of(
      yup.object({
        dob: yup
          .string()
          .required('Date of birth is required')
          .matches(/^(0[1-9]|1[0-2])\/\d{4}$/, 'Date of birth must be in MM/YYYY format')
          .test({
            name: 'teenager-age-range',
            test: (dob) => {
              if (!dob) return false;
              dob = formatDependentDOB(dob);
              return (
                differenceInMonths(new Date(), new Date(dob)) >= 13 * 12 &&
                differenceInMonths(new Date(), new Date(dob)) <= 18 * 12
              );
            },
            message: 'Teenagers must be between 13 and 18 years old',
          }),
      }),
    )
    .optional()
    .max(10),
});

export type ModelValues = {
  models: Option[];
  children?: { dob: string }[];
  teenagers?: { dob: string }[];
};
