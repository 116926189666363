import React from 'react';

import { BrkfstTag } from 'shared/typings/tag';
import { BrkfstUser } from 'shared/typings/user';

import CreatorView from './CreatorInfoPanel.creator';
import MarketerView from './CreatorInfoPanel.marketer';

interface Props {
  isCurrentUser: boolean;
  user: BrkfstUser;
  creatorTags: BrkfstTag[];
}
const CreatorInfoPanel: React.FC<Props> = ({ isCurrentUser, user, creatorTags }) => {
  return isCurrentUser ? (
    <CreatorView user={user} creatorTags={creatorTags} />
  ) : (
    <MarketerView user={user} creatorTags={creatorTags} />
  );
};

export default CreatorInfoPanel;
